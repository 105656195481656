// Override Bootstrap's base button class

.btn {
  --#{$prefix}btn-active-border-color: transparent;
  --#{$prefix}btn-disabled-border-color: transparent;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    box-shadow: $focus-ring-box-shadow;
  }
}


// Override solid buttons

@each $color, $value in map-remove($theme-colors, "secondary", "dark", "light") {
  .btn-#{$color} {
    --#{$prefix}btn-hover-bg: #{lighten($value, 10%)};
    --#{$prefix}btn-active-bg: #{lighten($value, 10%)};
    --#{$prefix}btn-hover-border-color: #{lighten($value, 10%)};
    --#{$prefix}btn-active-border-color: #{lighten($value, 10%)};
  }
}

.btn-dark {
  --#{$prefix}btn-bg: #{$gray-900};
  --#{$prefix}btn-border-color: #{$gray-900};
}

.btn-light {
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-bg: #{$primary};
  --#{$prefix}btn-hover-border-color: #{$primary};
  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$primary};
  --#{$prefix}btn-hover-border-color: #{$primary};
}


// Override outline buttons

.btn-outline-secondary {
  --#{$prefix}btn-color: #{$gray-900};
  --#{$prefix}btn-hover-color: #{$gray-900};
  --#{$prefix}btn-active-color: #{$gray-900};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-hover-bg: #{$white};
  --#{$prefix}btn-active-bg: #{$white};
  --#{$prefix}btn-border-color: #{$gray-300};
  --#{$prefix}btn-hover-border-color: #{$gray-300};
  --#{$prefix}btn-active-border-color: #{$gray-300};
}


// Icon button

.btn-icon {
  --#{$prefix}btn-size: #{$btn-icon-size};

  flex-shrink: 0;
  width: var(--#{$prefix}btn-size);
  height: var(--#{$prefix}btn-size);
  padding: 0;

  &.btn-lg {
    --#{$prefix}btn-size: #{$btn-icon-size-lg};
  }

  &.btn-sm {
    --#{$prefix}btn-size: #{$btn-icon-size-sm};
  }
}


// Coin button

.btn-coin {
  --#{$prefix}btn-padding-y: #{$spacer * .5};
  --#{$prefix}btn-padding-x: #{$spacer * .5};

  --#{$prefix}btn-color: #{$gray-900};
  --#{$prefix}btn-hover-color: #{$gray-900};
  --#{$prefix}btn-active-color: #{$gray-900};

  --#{$prefix}btn-bg: #{$white};
  --#{$prefix}btn-hover-bg: #{$white};
  --#{$prefix}btn-active-bg: #{$white};

  --#{$prefix}btn-border-color: #{$white};
  --#{$prefix}btn-hover-border-color: #{$gray-300};
  --#{$prefix}btn-active-border-color: #{$gray-300};
  --#{$prefix}btn-border-radius: #{$border-radius-pill};

  gap: $spacer * .75;
  justify-content: space-between;
  text-align: left;
  text-wrap: wrap;

  &:hover,
  &.active {
    .btn-coin-icon {
      opacity: 1;
    }
  }
}

.btn-coin-icon {
  opacity: 0;
  transition: opacity .25s ease-in-out;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}
