@charset "UTF-8";
/*!
 * CatPay Website
 * Copyright 2024 CatPay
 * Website styles
*/
@font-face {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300 800;
  src: url("../fonts/space-grotesk-variable-latin.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Sora";
  font-style: normal;
  font-weight: 100 800;
  src: url("../fonts/sora-variable-latin.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* stylelint-disable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines */
[class^=rounded].overflow-hidden,
[class*=" rounded"].overflow-hidden {
  transform: translateZ(0);
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute !important;
  }
}

/* stylelint-enable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines */
.scroll-mt {
  --cp-scroll-margin-top: 100px;
  scroll-margin-top: var(--cp-scroll-margin-top);
}
@media (max-width: 499.98px) {
  .scroll-mt {
    --cp-scroll-margin-top: 90px;
  }
}

:root,
[data-bs-theme=light] {
  --cp-primary: #5c66ef;
  --cp-secondary: #9396ad;
  --cp-success: #33b36b;
  --cp-info: #5ca8ef;
  --cp-warning: #efb45c;
  --cp-danger: #ef5c7f;
  --cp-light: #fff;
  --cp-dark: #121422;
  --cp-primary-rgb: 92, 102, 239;
  --cp-secondary-rgb: 147, 150, 173;
  --cp-success-rgb: 51, 179, 107;
  --cp-info-rgb: 92, 168, 239;
  --cp-warning-rgb: 239, 180, 92;
  --cp-danger-rgb: 239, 92, 127;
  --cp-light-rgb: 255, 255, 255;
  --cp-dark-rgb: 18, 20, 34;
  --cp-primary-text-emphasis: #1725e8;
  --cp-secondary-text-emphasis: #37394d;
  --cp-success-text-emphasis: #227747;
  --cp-info-text-emphasis: #1783e8;
  --cp-warning-text-emphasis: #e89417;
  --cp-danger-text-emphasis: #e81749;
  --cp-light-text-emphasis: #6a6e8b;
  --cp-dark-text-emphasis: #121422;
  --cp-primary-bg-subtle: #d6d9fb;
  --cp-secondary-bg-subtle: #eff2fd;
  --cp-success-bg-subtle: #ccecda;
  --cp-info-bg-subtle: #d6e9fb;
  --cp-warning-bg-subtle: #fbecd6;
  --cp-danger-bg-subtle: #fbd6df;
  --cp-light-bg-subtle: #fff;
  --cp-dark-bg-subtle: #ececed;
  --cp-primary-border-subtle: #aeb3f7;
  --cp-secondary-border-subtle: #eff2fc;
  --cp-success-border-subtle: #99d9b5;
  --cp-info-border-subtle: #aed4f7;
  --cp-warning-border-subtle: #f7daae;
  --cp-danger-border-subtle: #f7aebf;
  --cp-light-border-subtle: #eff2fd;
  --cp-dark-border-subtle: #eff2fc;
  --cp-white-rgb: 255, 255, 255;
  --cp-black-rgb: 0, 0, 0;
  --cp-font-sans-serif: "Space Grotesk", sans-serif;
  --cp-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --cp-gradient: linear-gradient(180deg, #fff 0%, rgba(211, 215, 240, 0.8) 50%, #fff 100%);
  --cp-root-font-size: 1rem;
  --cp-body-font-family: var(--cp-font-sans-serif);
  --cp-body-font-size: 1rem;
  --cp-body-font-weight: 400;
  --cp-body-line-height: 1.5;
  --cp-body-color: #37394d;
  --cp-body-color-rgb: 55, 57, 77;
  --cp-body-bg: #fff;
  --cp-body-bg-rgb: 255, 255, 255;
  --cp-emphasis-color: #26283c;
  --cp-emphasis-color-rgb: 38, 40, 60;
  --cp-secondary-color: #9396ad;
  --cp-secondary-color-rgb: 147, 150, 173;
  --cp-secondary-bg: #eff2fd;
  --cp-secondary-bg-rgb: 239, 242, 253;
  --cp-tertiary-color: #b4b7c9;
  --cp-tertiary-color-rgb: 180, 183, 201;
  --cp-tertiary-bg: #eff2fd;
  --cp-tertiary-bg-rgb: 239, 242, 253;
  --cp-heading-color: #121422;
  --cp-link-color: #5ca8ef;
  --cp-link-color-rgb: 92, 168, 239;
  --cp-link-decoration: underline;
  --cp-link-hover-color: #5ca8ef;
  --cp-link-hover-color-rgb: 92, 168, 239;
  --cp-link-hover-decoration: none;
  --cp-code-color: #d63384;
  --cp-highlight-color: #37394d;
  --cp-highlight-bg: #fff3cd;
  --cp-border-width: 1px;
  --cp-border-style: solid;
  --cp-border-color: #eff2fc;
  --cp-border-color-translucent: rgba(0, 0, 0, 0.1);
  --cp-border-radius: 0.5rem;
  --cp-border-radius-sm: calc(var(--cp-border-radius) * 0.75);
  --cp-border-radius-lg: calc(var(--cp-border-radius) * 1.5);
  --cp-border-radius-xl: calc(var(--cp-border-radius) * 2);
  --cp-border-radius-xxl: calc(var(--cp-border-radius) * 2.5);
  --cp-border-radius-2xl: var(--cp-border-radius-xxl);
  --cp-border-radius-pill: 50rem;
  --cp-box-shadow: 0 0.5rem 2rem -0.25rem rgba(103, 111, 123, 0.1);
  --cp-box-shadow-sm: 0 0.375rem 1.5rem rgba(103, 111, 123, 0.06);
  --cp-box-shadow-lg: 0 1.125rem 3rem -0.375rem rgba(103, 111, 123, 0.12);
  --cp-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --cp-focus-ring-width: 0.25rem;
  --cp-focus-ring-opacity: 0.1;
  --cp-focus-ring-color: rgba(18, 20, 34, 0.1);
  --cp-form-valid-color: #33b36b;
  --cp-form-valid-border-color: #33b36b;
  --cp-form-invalid-color: #ef5c7f;
  --cp-form-invalid-border-color: #ef5c7f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--cp-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--cp-body-font-family);
  font-size: var(--cp-body-font-size);
  font-weight: var(--cp-body-font-weight);
  line-height: var(--cp-body-line-height);
  color: var(--cp-body-color);
  text-align: var(--cp-body-text-align);
  background-color: var(--cp-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.5rem 0;
  color: var(--cp-border-color);
  border: 0;
  border-top: var(--cp-border-width) solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--cp-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--cp-highlight-color);
  background-color: var(--cp-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--cp-link-color-rgb), var(--cp-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --cp-link-color-rgb: var(--cp-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--cp-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: #fff;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--cp-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--cp-body-bg);
  background-color: var(--cp-emphasis-color);
  border-radius: calc(var(--cp-border-radius) * 0.75);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--cp-secondary-color);
  text-align: left;
}

th {
  font-weight: 700;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 400;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.3875rem + 1.65vw);
  font-weight: 700;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  color: var(--cp-body-color);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.375rem;
  background-color: var(--cp-body-bg);
  border: var(--cp-border-width) solid var(--cp-border-color);
  border-radius: var(--cp-border-radius-lg);
  box-shadow: none;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.75rem;
  color: var(--cp-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --cp-gutter-x: 3rem;
  --cp-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--cp-gutter-x) * 0.5);
  padding-left: calc(var(--cp-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 500px) {
  .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1344px;
  }
}
:root {
  --cp-breakpoint-xs: 0;
  --cp-breakpoint-sm: 500px;
  --cp-breakpoint-md: 768px;
  --cp-breakpoint-lg: 992px;
  --cp-breakpoint-xl: 1200px;
  --cp-breakpoint-xxl: 1400px;
}

.row {
  --cp-gutter-x: 1.5rem;
  --cp-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--cp-gutter-y));
  margin-right: calc(-0.5 * var(--cp-gutter-x));
  margin-left: calc(-0.5 * var(--cp-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--cp-gutter-x) * 0.5);
  padding-left: calc(var(--cp-gutter-x) * 0.5);
  margin-top: var(--cp-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --cp-gutter-x: 0;
}

.g-0,
.gy-0 {
  --cp-gutter-y: 0;
}

.g-1,
.gx-1 {
  --cp-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --cp-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --cp-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --cp-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --cp-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --cp-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --cp-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --cp-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --cp-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --cp-gutter-y: 3rem;
}

@media (min-width: 500px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --cp-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --cp-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --cp-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --cp-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --cp-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --cp-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --cp-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --cp-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --cp-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --cp-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --cp-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --cp-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --cp-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --cp-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --cp-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --cp-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --cp-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --cp-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --cp-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --cp-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --cp-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --cp-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --cp-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --cp-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --cp-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --cp-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --cp-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --cp-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --cp-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --cp-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --cp-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --cp-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --cp-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --cp-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --cp-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --cp-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --cp-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --cp-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --cp-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --cp-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --cp-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --cp-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --cp-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --cp-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --cp-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --cp-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --cp-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --cp-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --cp-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --cp-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --cp-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --cp-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --cp-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --cp-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --cp-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --cp-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --cp-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --cp-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --cp-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --cp-gutter-y: 3rem;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--cp-heading-color);
}

.col-form-label {
  padding-top: calc(1rem + var(--cp-border-width));
  padding-bottom: calc(1rem + var(--cp-border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: var(--cp-heading-color);
}

.col-form-label-lg {
  padding-top: calc(1.25rem + var(--cp-border-width));
  padding-bottom: calc(1.25rem + var(--cp-border-width));
  font-size: 1.125rem;
}

.col-form-label-sm {
  padding-top: calc(0.75rem + var(--cp-border-width));
  padding-bottom: calc(0.75rem + var(--cp-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--cp-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #121422;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--cp-border-width) solid #e2e5f1;
  border-radius: var(--cp-border-radius-xl);
  box-shadow: var(--cp-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #121422;
  background-color: #fff;
  border-color: #5c66ef;
  outline: 0;
  box-shadow: var(--cp-box-shadow-inset), unset;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--cp-tertiary-color);
  opacity: 1;
}
.form-control:disabled {
  color: var(--cp-tertiary-color);
  background-color: var(--cp-tertiary-bg);
  border-color: var(--cp-border-color);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 1rem 1rem;
  margin: -1rem -1rem;
  margin-inline-end: 1rem;
  color: #121422;
  background-color: var(--cp-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--cp-border-width);
  border-radius: 0;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--cp-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--cp-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--cp-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 1.5rem + calc(var(--cp-border-width) * 2));
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  border-radius: var(--cp-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.75rem 1.5rem;
  margin: -0.75rem -1.5rem;
  margin-inline-end: 1.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 2.5rem + calc(var(--cp-border-width) * 2));
  padding: 1.25rem 2rem;
  font-size: 1.125rem;
  border-radius: var(--cp-border-radius);
}
.form-control-lg::file-selector-button {
  padding: 1.25rem 2rem;
  margin: -1.25rem -2rem;
  margin-inline-end: 2rem;
}

textarea.form-control {
  min-height: calc(1.5em + 2rem + calc(var(--cp-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 1.5rem + calc(var(--cp-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 2.5rem + calc(var(--cp-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 2rem + calc(var(--cp-border-width) * 2));
  padding: 1rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--cp-border-radius-xl);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--cp-border-radius-xl);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 1.5rem + calc(var(--cp-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 2.5rem + calc(var(--cp-border-width) * 2));
}

.form-select {
  --cp-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236a6e8b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 1rem 2.25rem 1rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #121422;
  appearance: none;
  background-color: #fff;
  background-image: var(--cp-form-select-bg-img), var(--cp-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 15px 11px;
  border: var(--cp-border-width) solid #e2e5f1;
  border-radius: var(--cp-border-radius-xl);
  box-shadow: var(--cp-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #5c66ef;
  outline: 0;
  box-shadow: var(--cp-box-shadow-inset), 0 0 0 0 rgba(18, 20, 34, 0.1);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--cp-tertiary-bg);
  border-color: var(--cp-border-color);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #121422;
}

.form-select-sm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  font-size: 0.875rem;
  border-radius: var(--cp-border-radius-sm);
}

.form-select-lg {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 2rem;
  font-size: 1.125rem;
  border-radius: var(--cp-border-radius);
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.25rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --cp-form-check-bg: #fff;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--cp-form-check-bg);
  background-image: var(--cp-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--cp-border-width) solid #b4b7c9;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #5c66ef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 20, 34, 0.1);
}
.form-check-input:checked {
  background-color: #5c66ef;
  border-color: #5c66ef;
}
.form-check-input:checked[type=checkbox] {
  --cp-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --cp-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #5c66ef;
  border-color: #5c66ef;
  --cp-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-label {
  color: #121422;
}

.form-switch {
  padding-left: 3.375rem;
}
.form-switch .form-check-input {
  --cp-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  width: 2.75rem;
  margin-left: -3.375rem;
  background-image: var(--cp-form-switch-bg);
  background-position: left center;
  border-radius: 2.75rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --cp-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --cp-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3.375rem;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3.375rem;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.55;
}

.form-range {
  width: 100%;
  height: 0.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: -0.1875rem;
  appearance: none;
  background-color: var(--cp-body-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.125rem var(--cp-emphasis-color);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: var(--cp-emphasis-color);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--cp-border-color);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--cp-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 0.5rem;
  height: 0.5rem;
  appearance: none;
  background-color: var(--cp-body-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.125rem var(--cp-emphasis-color);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: var(--cp-emphasis-color);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--cp-border-color);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--cp-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--cp-secondary-bg);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--cp-secondary-bg);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--cp-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--cp-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--cp-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--cp-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: var(--cp-border-radius-xl);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--cp-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--cp-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6a6e8b;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--cp-tertiary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #121422;
  text-align: center;
  white-space: nowrap;
  background-color: var(--cp-tertiary-bg);
  border: var(--cp-border-width) solid #e2e5f1;
  border-radius: var(--cp-border-radius-xl);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.25rem 2rem;
  font-size: 1.125rem;
  border-radius: var(--cp-border-radius);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  border-radius: var(--cp-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.25rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--cp-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--cp-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--cp-success);
  border-radius: var(--cp-border-radius-sm);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--cp-form-valid-border-color);
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2333b36b'%3e%3cpath d='M10.2,3.8c1.8-0.4,3.6-0.2,5.3,0.5c0.4,0.2,0.9,0,1.1-0.4s0-0.9-0.4-1.1c0,0,0,0,0,0c-5-2.2-10.9,0-13.2,5.1s0,10.9,5.1,13.2s10.9,0,13.2-5.1c0.6-1.3,0.9-2.7,0.9-4.1v-0.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8l0,0V12c0,4.6-3.8,8.4-8.4,8.4S3.6,16.6,3.6,12C3.6,8.1,6.3,4.7,10.2,3.8z'/%3e%3cpath d='M21.8,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L12,12.7l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L21.8,5.2L21.8,5.2z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--cp-form-valid-border-color);
  box-shadow: var(--cp-box-shadow-inset), 0 0 0 0 rgba(var(--cp-success-rgb), 0.1);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--cp-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --cp-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2333b36b'%3e%3cpath d='M10.2,3.8c1.8-0.4,3.6-0.2,5.3,0.5c0.4,0.2,0.9,0,1.1-0.4s0-0.9-0.4-1.1c0,0,0,0,0,0c-5-2.2-10.9,0-13.2,5.1s0,10.9,5.1,13.2s10.9,0,13.2-5.1c0.6-1.3,0.9-2.7,0.9-4.1v-0.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8l0,0V12c0,4.6-3.8,8.4-8.4,8.4S3.6,16.6,3.6,12C3.6,8.1,6.3,4.7,10.2,3.8z'/%3e%3cpath d='M21.8,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L12,12.7l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L21.8,5.2L21.8,5.2z'/%3e%3c/svg%3e");
  padding-right: 4.75rem;
  background-position: right 1rem center, center right 2.25rem;
  background-size: 15px 11px, calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--cp-form-valid-border-color);
  box-shadow: var(--cp-box-shadow-inset), 0 0 0 0 rgba(var(--cp-success-rgb), 0.1);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 2rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--cp-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--cp-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(var(--cp-success-rgb), 0.1);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--cp-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--cp-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--cp-danger);
  border-radius: var(--cp-border-radius-sm);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--cp-form-invalid-border-color);
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ef5c7f'%3e%3cpath d='M12,1.5C6.2,1.5,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.5,17.8,22.5,12S17.8,1.5,12,1.5z M12,20.7c-4.8,0-8.7-4-8.7-8.7S7.2,3.3,12,3.3s8.7,4,8.7,8.7S16.8,20.7,12,20.7z'/%3e%3cpath d='M15.7,14.5c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0L12,13.2l-2.5,2.5c-0.3,0.3-0.9,0.3-1.2,0s-0.3-0.9,0-1.2l2.5-2.5L8.3,9.5c-0.4-0.3-0.4-0.9,0-1.2c0.3-0.4,0.9-0.4,1.2,0l2.5,2.5l2.5-2.5c0.3-0.3,0.9-0.3,1.2,0s0.3,0.9,0,1.2L13.2,12L15.7,14.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--cp-form-invalid-border-color);
  box-shadow: var(--cp-box-shadow-inset), 0 0 0 0 rgba(var(--cp-danger-rgb), 0.1);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--cp-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --cp-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ef5c7f'%3e%3cpath d='M12,1.5C6.2,1.5,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.5,17.8,22.5,12S17.8,1.5,12,1.5z M12,20.7c-4.8,0-8.7-4-8.7-8.7S7.2,3.3,12,3.3s8.7,4,8.7,8.7S16.8,20.7,12,20.7z'/%3e%3cpath d='M15.7,14.5c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0L12,13.2l-2.5,2.5c-0.3,0.3-0.9,0.3-1.2,0s-0.3-0.9,0-1.2l2.5-2.5L8.3,9.5c-0.4-0.3-0.4-0.9,0-1.2c0.3-0.4,0.9-0.4,1.2,0l2.5,2.5l2.5-2.5c0.3-0.3,0.9-0.3,1.2,0s0.3,0.9,0,1.2L13.2,12L15.7,14.5z'/%3e%3c/svg%3e");
  padding-right: 4.75rem;
  background-position: right 1rem center, center right 2.25rem;
  background-size: 15px 11px, calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--cp-form-invalid-border-color);
  box-shadow: var(--cp-box-shadow-inset), 0 0 0 0 rgba(var(--cp-danger-rgb), 0.1);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 2rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--cp-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--cp-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(var(--cp-danger-rgb), 0.1);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--cp-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --cp-btn-padding-x: 1.75rem;
  --cp-btn-padding-y: 1rem;
  --cp-btn-font-family: ;
  --cp-btn-font-size: 1rem;
  --cp-btn-font-weight: 700;
  --cp-btn-line-height: 1.125rem;
  --cp-btn-color: var(--cp-component-color);
  --cp-btn-bg: transparent;
  --cp-btn-border-width: var(--cp-border-width);
  --cp-btn-border-color: transparent;
  --cp-btn-border-radius: var(--cp-border-radius-lg);
  --cp-btn-hover-border-color: transparent;
  --cp-btn-box-shadow: none;
  --cp-btn-disabled-opacity: 0.55;
  --cp-btn-focus-box-shadow: 0 0 0 0 rgba(var(--cp-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--cp-btn-padding-y) var(--cp-btn-padding-x);
  font-family: var(--cp-btn-font-family);
  font-size: var(--cp-btn-font-size);
  font-weight: var(--cp-btn-font-weight);
  line-height: var(--cp-btn-line-height);
  color: var(--cp-btn-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--cp-btn-border-width) solid var(--cp-btn-border-color);
  border-radius: var(--cp-btn-border-radius);
  background-color: var(--cp-btn-bg);
  box-shadow: var(--cp-btn-box-shadow);
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--cp-btn-hover-color);
  background-color: var(--cp-btn-hover-bg);
  border-color: var(--cp-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--cp-btn-color);
  background-color: var(--cp-btn-bg);
  border-color: var(--cp-btn-border-color);
}
.btn:focus-visible {
  color: var(--cp-btn-hover-color);
  background-color: var(--cp-btn-hover-bg);
  border-color: var(--cp-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--cp-btn-box-shadow), var(--cp-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--cp-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--cp-btn-box-shadow), var(--cp-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--cp-btn-active-color);
  background-color: var(--cp-btn-active-bg);
  border-color: var(--cp-btn-active-border-color);
  box-shadow: var(--cp-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--cp-btn-active-shadow), var(--cp-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--cp-btn-active-shadow), var(--cp-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--cp-btn-disabled-color);
  pointer-events: none;
  background-color: var(--cp-btn-disabled-bg);
  border-color: var(--cp-btn-disabled-border-color);
  opacity: var(--cp-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --cp-btn-color: #fff;
  --cp-btn-bg: #5c66ef;
  --cp-btn-border-color: #5c66ef;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #4e57cb;
  --cp-btn-hover-border-color: #4a52bf;
  --cp-btn-focus-shadow-rgb: 116, 125, 241;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #4a52bf;
  --cp-btn-active-border-color: #454db3;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #fff;
  --cp-btn-disabled-bg: #5c66ef;
  --cp-btn-disabled-border-color: #5c66ef;
}

.btn-secondary {
  --cp-btn-color: #fff;
  --cp-btn-bg: #9396ad;
  --cp-btn-border-color: #9396ad;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #7d8093;
  --cp-btn-hover-border-color: #76788a;
  --cp-btn-focus-shadow-rgb: 163, 166, 185;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #76788a;
  --cp-btn-active-border-color: #6e7182;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #fff;
  --cp-btn-disabled-bg: #9396ad;
  --cp-btn-disabled-border-color: #9396ad;
}

.btn-success {
  --cp-btn-color: #fff;
  --cp-btn-bg: #33b36b;
  --cp-btn-border-color: #33b36b;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #2b985b;
  --cp-btn-hover-border-color: #298f56;
  --cp-btn-focus-shadow-rgb: 82, 190, 129;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #298f56;
  --cp-btn-active-border-color: #268650;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #fff;
  --cp-btn-disabled-bg: #33b36b;
  --cp-btn-disabled-border-color: #33b36b;
}

.btn-info {
  --cp-btn-color: #fff;
  --cp-btn-bg: #5ca8ef;
  --cp-btn-border-color: #5ca8ef;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #4e8fcb;
  --cp-btn-hover-border-color: #4a86bf;
  --cp-btn-focus-shadow-rgb: 116, 181, 241;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #4a86bf;
  --cp-btn-active-border-color: #457eb3;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #fff;
  --cp-btn-disabled-bg: #5ca8ef;
  --cp-btn-disabled-border-color: #5ca8ef;
}

.btn-warning {
  --cp-btn-color: #000;
  --cp-btn-bg: #efb45c;
  --cp-btn-border-color: #efb45c;
  --cp-btn-hover-color: #000;
  --cp-btn-hover-bg: #f1bf74;
  --cp-btn-hover-border-color: #f1bc6c;
  --cp-btn-focus-shadow-rgb: 203, 153, 78;
  --cp-btn-active-color: #000;
  --cp-btn-active-bg: #f2c37d;
  --cp-btn-active-border-color: #f1bc6c;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #000;
  --cp-btn-disabled-bg: #efb45c;
  --cp-btn-disabled-border-color: #efb45c;
}

.btn-danger {
  --cp-btn-color: #fff;
  --cp-btn-bg: #ef5c7f;
  --cp-btn-border-color: #ef5c7f;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #cb4e6c;
  --cp-btn-hover-border-color: #bf4a66;
  --cp-btn-focus-shadow-rgb: 241, 116, 146;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #bf4a66;
  --cp-btn-active-border-color: #b3455f;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #fff;
  --cp-btn-disabled-bg: #ef5c7f;
  --cp-btn-disabled-border-color: #ef5c7f;
}

.btn-light {
  --cp-btn-color: #000;
  --cp-btn-bg: #fff;
  --cp-btn-border-color: #fff;
  --cp-btn-hover-color: #000;
  --cp-btn-hover-bg: #d9d9d9;
  --cp-btn-hover-border-color: #cccccc;
  --cp-btn-focus-shadow-rgb: 217, 217, 217;
  --cp-btn-active-color: #000;
  --cp-btn-active-bg: #cccccc;
  --cp-btn-active-border-color: #bfbfbf;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #000;
  --cp-btn-disabled-bg: #fff;
  --cp-btn-disabled-border-color: #fff;
}

.btn-dark {
  --cp-btn-color: #fff;
  --cp-btn-bg: #121422;
  --cp-btn-border-color: #121422;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #363743;
  --cp-btn-hover-border-color: #2a2c38;
  --cp-btn-focus-shadow-rgb: 54, 55, 67;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #41434e;
  --cp-btn-active-border-color: #2a2c38;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #fff;
  --cp-btn-disabled-bg: #121422;
  --cp-btn-disabled-border-color: #121422;
}

.btn-outline-primary {
  --cp-btn-color: #5c66ef;
  --cp-btn-border-color: #5c66ef;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #5c66ef;
  --cp-btn-hover-border-color: #5c66ef;
  --cp-btn-focus-shadow-rgb: 92, 102, 239;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #5c66ef;
  --cp-btn-active-border-color: #5c66ef;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #5c66ef;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #5c66ef;
  --cp-gradient: none;
}

.btn-outline-secondary {
  --cp-btn-color: #9396ad;
  --cp-btn-border-color: #9396ad;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #9396ad;
  --cp-btn-hover-border-color: #9396ad;
  --cp-btn-focus-shadow-rgb: 147, 150, 173;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #9396ad;
  --cp-btn-active-border-color: #9396ad;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #9396ad;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #9396ad;
  --cp-gradient: none;
}

.btn-outline-success {
  --cp-btn-color: #33b36b;
  --cp-btn-border-color: #33b36b;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #33b36b;
  --cp-btn-hover-border-color: #33b36b;
  --cp-btn-focus-shadow-rgb: 51, 179, 107;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #33b36b;
  --cp-btn-active-border-color: #33b36b;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #33b36b;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #33b36b;
  --cp-gradient: none;
}

.btn-outline-info {
  --cp-btn-color: #5ca8ef;
  --cp-btn-border-color: #5ca8ef;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #5ca8ef;
  --cp-btn-hover-border-color: #5ca8ef;
  --cp-btn-focus-shadow-rgb: 92, 168, 239;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #5ca8ef;
  --cp-btn-active-border-color: #5ca8ef;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #5ca8ef;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #5ca8ef;
  --cp-gradient: none;
}

.btn-outline-warning {
  --cp-btn-color: #efb45c;
  --cp-btn-border-color: #efb45c;
  --cp-btn-hover-color: #000;
  --cp-btn-hover-bg: #efb45c;
  --cp-btn-hover-border-color: #efb45c;
  --cp-btn-focus-shadow-rgb: 239, 180, 92;
  --cp-btn-active-color: #000;
  --cp-btn-active-bg: #efb45c;
  --cp-btn-active-border-color: #efb45c;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #efb45c;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #efb45c;
  --cp-gradient: none;
}

.btn-outline-danger {
  --cp-btn-color: #ef5c7f;
  --cp-btn-border-color: #ef5c7f;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #ef5c7f;
  --cp-btn-hover-border-color: #ef5c7f;
  --cp-btn-focus-shadow-rgb: 239, 92, 127;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #ef5c7f;
  --cp-btn-active-border-color: #ef5c7f;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #ef5c7f;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #ef5c7f;
  --cp-gradient: none;
}

.btn-outline-light {
  --cp-btn-color: #fff;
  --cp-btn-border-color: #fff;
  --cp-btn-hover-color: #000;
  --cp-btn-hover-bg: #fff;
  --cp-btn-hover-border-color: #fff;
  --cp-btn-focus-shadow-rgb: 255, 255, 255;
  --cp-btn-active-color: #000;
  --cp-btn-active-bg: #fff;
  --cp-btn-active-border-color: #fff;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #fff;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #fff;
  --cp-gradient: none;
}

.btn-outline-dark {
  --cp-btn-color: #121422;
  --cp-btn-border-color: #121422;
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #121422;
  --cp-btn-hover-border-color: #121422;
  --cp-btn-focus-shadow-rgb: 18, 20, 34;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #121422;
  --cp-btn-active-border-color: #121422;
  --cp-btn-active-shadow: none;
  --cp-btn-disabled-color: #121422;
  --cp-btn-disabled-bg: transparent;
  --cp-btn-disabled-border-color: #121422;
  --cp-gradient: none;
}

.btn-link {
  --cp-btn-font-weight: 400;
  --cp-btn-color: #121422;
  --cp-btn-bg: transparent;
  --cp-btn-border-color: transparent;
  --cp-btn-hover-color: #5c66ef;
  --cp-btn-hover-border-color: transparent;
  --cp-btn-active-color: #5c66ef;
  --cp-btn-active-border-color: transparent;
  --cp-btn-disabled-color: var(--cp-component-disabled-color);
  --cp-btn-disabled-border-color: transparent;
  --cp-btn-box-shadow: 0 0 0 #000;
  --cp-btn-focus-shadow-rgb: 116, 181, 241;
  text-decoration: underline;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--cp-btn-color);
}
.btn-link:hover {
  color: var(--cp-btn-hover-color);
}

.btn-lg {
  --cp-btn-padding-y: 1.25rem;
  --cp-btn-padding-x: 2rem;
  --cp-btn-font-size: 1.125rem;
  --cp-btn-border-radius: var(--cp-border-radius-xl);
}

.btn-sm {
  --cp-btn-padding-y: 0.75rem;
  --cp-btn-padding-x: 1.5rem;
  --cp-btn-font-size: 0.875rem;
  --cp-btn-border-radius: 0.625rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid;
  border-right: 1.275em solid transparent;
  border-bottom: 0;
  border-left: 1.275em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --cp-dropdown-zindex: 1000;
  --cp-dropdown-min-width: 12.5rem;
  --cp-dropdown-padding-x: 1rem;
  --cp-dropdown-padding-y: 1rem;
  --cp-dropdown-spacer: 0.25rem;
  --cp-dropdown-font-size: 1rem;
  --cp-dropdown-color: var(--cp-component-color);
  --cp-dropdown-bg: var(--cp-body-bg);
  --cp-dropdown-border-color: var(--cp-light-border-subtle);
  --cp-dropdown-border-radius: var(--cp-border-radius-xl);
  --cp-dropdown-border-width: var(--cp-border-width);
  --cp-dropdown-inner-border-radius: calc(var(--cp-border-radius-xl) - var(--cp-border-width));
  --cp-dropdown-divider-bg: var(--cp-border-color);
  --cp-dropdown-divider-margin-y: 0.375rem;
  --cp-dropdown-box-shadow: var(--cp-box-shadow);
  --cp-dropdown-link-color: #121422;
  --cp-dropdown-link-hover-color: #121422;
  --cp-dropdown-link-hover-bg: var(--cp-component-hover-bg);
  --cp-dropdown-link-active-color: #121422;
  --cp-dropdown-link-active-bg: var(--cp-component-active-bg);
  --cp-dropdown-link-disabled-color: var(--cp-component-disabled-color);
  --cp-dropdown-item-padding-x: 1rem;
  --cp-dropdown-item-padding-y: 0.75rem;
  --cp-dropdown-header-color: var(--cp-heading-color);
  --cp-dropdown-header-padding-x: 1rem;
  --cp-dropdown-header-padding-y: 0.75rem;
  position: absolute;
  z-index: var(--cp-dropdown-zindex);
  display: none;
  min-width: var(--cp-dropdown-min-width);
  padding: var(--cp-dropdown-padding-y) var(--cp-dropdown-padding-x);
  margin: 0;
  font-size: var(--cp-dropdown-font-size);
  color: var(--cp-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--cp-dropdown-bg);
  background-clip: padding-box;
  border: var(--cp-dropdown-border-width) solid var(--cp-dropdown-border-color);
  border-radius: var(--cp-dropdown-border-radius);
  box-shadow: var(--cp-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--cp-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 500px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--cp-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 0;
  border-right: 1.275em solid transparent;
  border-bottom: 1.275em solid;
  border-left: 1.275em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--cp-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid transparent;
  border-right: 0;
  border-bottom: 1.275em solid transparent;
  border-left: 1.275em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--cp-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid transparent;
  border-right: 1.275em solid;
  border-bottom: 1.275em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--cp-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--cp-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--cp-dropdown-item-padding-y) var(--cp-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--cp-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--cp-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--cp-dropdown-link-hover-color);
  background-color: var(--cp-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--cp-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--cp-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--cp-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--cp-dropdown-header-padding-y) var(--cp-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--cp-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--cp-dropdown-item-padding-y) var(--cp-dropdown-item-padding-x);
  color: var(--cp-dropdown-link-color);
}

.dropdown-menu-dark {
  --cp-dropdown-color: #eff2fc;
  --cp-dropdown-bg: #121422;
  --cp-dropdown-border-color: #37394d;
  --cp-dropdown-box-shadow: 0 0.5rem 1.875rem -0.25rem rgba(8, 11, 18, 0.35);
  --cp-dropdown-link-color: #eff2fc;
  --cp-dropdown-link-hover-color: #fff;
  --cp-dropdown-divider-bg: var(--cp-border-color);
  --cp-dropdown-link-hover-bg: #26283c;
  --cp-dropdown-link-active-color: #fff;
  --cp-dropdown-link-active-bg: #37394d;
  --cp-dropdown-link-disabled-color: #9396ad;
  --cp-dropdown-header-color: #fff;
}

.nav {
  --cp-nav-link-padding-x: 1.25rem;
  --cp-nav-link-padding-y: 0.5rem;
  --cp-nav-link-font-size: 0.875rem;
  --cp-nav-link-font-weight: 500;
  --cp-nav-link-color: var(--cp-component-color);
  --cp-nav-link-hover-color: var(--cp-component-hover-color);
  --cp-nav-link-disabled-color: var(--cp-component-disabled-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--cp-nav-link-padding-y) var(--cp-nav-link-padding-x);
  font-size: var(--cp-nav-link-font-size);
  font-weight: var(--cp-nav-link-font-weight);
  color: var(--cp-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--cp-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 20, 34, 0.1);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--cp-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --cp-nav-tabs-border-width: 0;
  --cp-nav-tabs-border-color: transparent;
  --cp-nav-tabs-border-radius: var(--cp-border-radius);
  --cp-nav-tabs-link-hover-border-color: transparent;
  --cp-nav-tabs-link-active-color: var(--cp-emphasis-color);
  --cp-nav-tabs-link-active-bg: #fff;
  --cp-nav-tabs-link-active-border-color: transparent;
  border-bottom: var(--cp-nav-tabs-border-width) solid var(--cp-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--cp-nav-tabs-border-width));
  border: var(--cp-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--cp-nav-tabs-border-radius);
  border-top-right-radius: var(--cp-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--cp-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--cp-nav-tabs-link-active-color);
  background-color: var(--cp-nav-tabs-link-active-bg);
  border-color: var(--cp-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--cp-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --cp-nav-pills-border-radius: 50rem;
  --cp-nav-pills-link-active-color: var(--cp-component-active-color);
  --cp-nav-pills-link-active-bg: transparent;
}
.nav-pills .nav-link {
  border-radius: var(--cp-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--cp-nav-pills-link-active-color);
  background-color: var(--cp-nav-pills-link-active-bg);
}

.nav-underline {
  --cp-nav-underline-gap: 0.5rem 1.75rem;
  --cp-nav-underline-border-width: var(--cp-border-width);
  --cp-nav-underline-link-active-color: var(--cp-component-active-color);
  gap: var(--cp-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--cp-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--cp-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --cp-navbar-padding-x: 0;
  --cp-navbar-padding-y: 0.75rem;
  --cp-navbar-color: #121422;
  --cp-navbar-hover-color: #121422;
  --cp-navbar-disabled-color: #b4b7c9;
  --cp-navbar-active-color: #121422;
  --cp-navbar-brand-padding-y: 0;
  --cp-navbar-brand-margin-end: 1rem;
  --cp-navbar-brand-font-size: 1.5rem;
  --cp-navbar-brand-color: #121422;
  --cp-navbar-brand-hover-color: #121422;
  --cp-navbar-nav-link-padding-x: 1.5rem;
  --cp-navbar-toggler-padding-y: 0.625rem;
  --cp-navbar-toggler-padding-x: 0.25rem;
  --cp-navbar-toggler-font-size: 1.125rem;
  --cp-navbar-toggler-icon-bg: initial;
  --cp-navbar-toggler-border-color: transparent;
  --cp-navbar-toggler-border-radius: 0;
  --cp-navbar-toggler-focus-width: 0;
  --cp-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--cp-navbar-padding-y) var(--cp-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--cp-navbar-brand-padding-y);
  padding-bottom: var(--cp-navbar-brand-padding-y);
  margin-right: var(--cp-navbar-brand-margin-end);
  font-size: var(--cp-navbar-brand-font-size);
  color: var(--cp-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--cp-navbar-brand-hover-color);
}

.navbar-nav {
  --cp-nav-link-padding-x: 0;
  --cp-nav-link-padding-y: 0.5rem;
  --cp-nav-link-font-size: 0.875rem;
  --cp-nav-link-font-weight: 500;
  --cp-nav-link-color: var(--cp-navbar-color);
  --cp-nav-link-hover-color: var(--cp-navbar-hover-color);
  --cp-nav-link-disabled-color: var(--cp-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--cp-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--cp-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--cp-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--cp-navbar-toggler-padding-y) var(--cp-navbar-toggler-padding-x);
  font-size: var(--cp-navbar-toggler-font-size);
  line-height: 1;
  color: var(--cp-navbar-color);
  background-color: transparent;
  border: var(--cp-border-width) solid var(--cp-navbar-toggler-border-color);
  border-radius: var(--cp-navbar-toggler-border-radius);
  transition: var(--cp-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--cp-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--cp-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--cp-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 500px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--cp-navbar-nav-link-padding-x);
    padding-left: var(--cp-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--cp-navbar-nav-link-padding-x);
    padding-left: var(--cp-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--cp-navbar-nav-link-padding-x);
    padding-left: var(--cp-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--cp-navbar-nav-link-padding-x);
    padding-left: var(--cp-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--cp-navbar-nav-link-padding-x);
    padding-left: var(--cp-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--cp-navbar-nav-link-padding-x);
  padding-left: var(--cp-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --cp-navbar-color: #fff;
  --cp-navbar-hover-color: #5c66ef;
  --cp-navbar-disabled-color: rgba(255, 255, 255, 0.4);
  --cp-navbar-active-color: #5c66ef;
  --cp-navbar-brand-color: #fff;
  --cp-navbar-brand-hover-color: #fff;
  --cp-navbar-toggler-border-color: transparent;
  --cp-navbar-toggler-icon-bg: initial;
}

.card {
  --cp-card-spacer-y: 1.5rem;
  --cp-card-spacer-x: 1.5rem;
  --cp-card-title-spacer-y: 0.75rem;
  --cp-card-title-color: var(--cp-heading-color);
  --cp-card-subtitle-color: ;
  --cp-card-border-width: var(--cp-border-width);
  --cp-card-border-color: var(--cp-border-color-translucent);
  --cp-card-border-radius: calc(var(--cp-border-radius) * 2);
  --cp-card-box-shadow: ;
  --cp-card-inner-border-radius: calc(calc(var(--cp-border-radius) * 2) - (var(--cp-border-width)));
  --cp-card-cap-padding-y: 0.75rem;
  --cp-card-cap-padding-x: 1.5rem;
  --cp-card-cap-bg: rgba(var(--cp-body-color-rgb), 0.03);
  --cp-card-cap-color: ;
  --cp-card-height: ;
  --cp-card-color: ;
  --cp-card-bg: var(--cp-body-bg);
  --cp-card-img-overlay-padding: 1.5rem;
  --cp-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--cp-card-height);
  color: var(--cp-body-color);
  word-wrap: break-word;
  background-color: var(--cp-card-bg);
  background-clip: border-box;
  border: var(--cp-card-border-width) solid var(--cp-card-border-color);
  border-radius: var(--cp-card-border-radius);
  box-shadow: var(--cp-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--cp-card-inner-border-radius);
  border-top-right-radius: var(--cp-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--cp-card-inner-border-radius);
  border-bottom-left-radius: var(--cp-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--cp-card-spacer-y) var(--cp-card-spacer-x);
  color: var(--cp-card-color);
}

.card-title {
  margin-bottom: var(--cp-card-title-spacer-y);
  color: var(--cp-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--cp-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--cp-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--cp-card-spacer-x);
}

.card-header {
  padding: var(--cp-card-cap-padding-y) var(--cp-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--cp-card-cap-color);
  background-color: var(--cp-card-cap-bg);
  border-bottom: var(--cp-card-border-width) solid var(--cp-card-border-color);
}
.card-header:first-child {
  border-radius: var(--cp-card-inner-border-radius) var(--cp-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--cp-card-cap-padding-y) var(--cp-card-cap-padding-x);
  color: var(--cp-card-cap-color);
  background-color: var(--cp-card-cap-bg);
  border-top: var(--cp-card-border-width) solid var(--cp-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--cp-card-inner-border-radius) var(--cp-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--cp-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--cp-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--cp-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--cp-card-bg);
  border-bottom-color: var(--cp-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--cp-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--cp-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--cp-card-img-overlay-padding);
  border-radius: var(--cp-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--cp-card-inner-border-radius);
  border-top-right-radius: var(--cp-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--cp-card-inner-border-radius);
  border-bottom-left-radius: var(--cp-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--cp-card-group-margin);
}
@media (min-width: 500px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --cp-accordion-color: #37394d;
  --cp-accordion-bg: transparent;
  --cp-accordion-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, border-radius 0.15s ease;
  --cp-accordion-border-color: #eff2fc;
  --cp-accordion-border-width: 0;
  --cp-accordion-border-radius: var(--cp-border-radius);
  --cp-accordion-inner-border-radius: calc(var(--cp-border-radius) - 0);
  --cp-accordion-btn-padding-x: 0;
  --cp-accordion-btn-padding-y: 1.5rem;
  --cp-accordion-btn-color: #121422;
  --cp-accordion-btn-bg: var(--cp-accordion-bg);
  --cp-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3e%3cpath fill-rule='evenodd' d='M7.999.667c.368 0 .667.298.667.667v6h6c.368 0 .667.298.667.667s-.299.667-.667.667h-6v6c0 .368-.298.667-.667.667s-.667-.299-.667-.667v-6h-6C.964 8.667.666 8.369.666 8s.298-.667.667-.667h6v-6c0-.368.298-.667.667-.667z' fill='%23121422'/%3e%3c/svg%3e");
  --cp-accordion-btn-icon-width: 1.25rem;
  --cp-accordion-btn-icon-transform: initial;
  --cp-accordion-btn-icon-transition: transform 0.3s ease-in-out;
  --cp-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3e%3cpath fill-rule='evenodd' d='M.666 8c0-.368.298-.667.667-.667v1.333C.964 8.666.666 8.368.666 8zm14 0v.667H1.333V7.333h13.333V8zm0 0v.667c.368 0 .667-.298.667-.667s-.299-.667-.667-.667V8z' fill='%23121422'/%3e%3c/svg%3e");
  --cp-accordion-btn-focus-box-shadow: unset;
  --cp-accordion-body-padding-x: 0;
  --cp-accordion-body-padding-y: 1.25rem;
  --cp-accordion-active-color: #121422;
  --cp-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--cp-accordion-btn-padding-y) var(--cp-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--cp-accordion-btn-color);
  text-align: left;
  background-color: var(--cp-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--cp-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--cp-accordion-active-color);
  background-color: var(--cp-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--cp-accordion-border-width)) 0 var(--cp-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--cp-accordion-btn-active-icon);
  transform: var(--cp-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--cp-accordion-btn-icon-width);
  height: var(--cp-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--cp-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--cp-accordion-btn-icon-width);
  transition: var(--cp-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--cp-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--cp-accordion-color);
  background-color: var(--cp-accordion-bg);
  border: var(--cp-accordion-border-width) solid var(--cp-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--cp-accordion-border-radius);
  border-top-right-radius: var(--cp-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--cp-accordion-inner-border-radius);
  border-top-right-radius: var(--cp-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--cp-accordion-border-radius);
  border-bottom-left-radius: var(--cp-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--cp-accordion-inner-border-radius);
  border-bottom-left-radius: var(--cp-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--cp-accordion-border-radius);
  border-bottom-left-radius: var(--cp-accordion-border-radius);
}

.accordion-body {
  padding: var(--cp-accordion-body-padding-y) var(--cp-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

.breadcrumb {
  --cp-breadcrumb-padding-x: 0;
  --cp-breadcrumb-padding-y: 0;
  --cp-breadcrumb-margin-bottom: 1rem;
  --cp-breadcrumb-font-size: 0.875rem;
  --cp-breadcrumb-bg: ;
  --cp-breadcrumb-border-radius: ;
  --cp-breadcrumb-divider-color: #121422;
  --cp-breadcrumb-item-padding-x: 0.375rem;
  --cp-breadcrumb-item-active-color: #6a6e8b;
  display: flex;
  flex-wrap: wrap;
  padding: var(--cp-breadcrumb-padding-y) var(--cp-breadcrumb-padding-x);
  margin-bottom: var(--cp-breadcrumb-margin-bottom);
  font-size: var(--cp-breadcrumb-font-size);
  list-style: none;
  background-color: var(--cp-breadcrumb-bg);
  border-radius: var(--cp-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--cp-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--cp-breadcrumb-item-padding-x);
  color: var(--cp-breadcrumb-divider-color);
  content: var(--cp-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23121422'%3e%3cpath d='M8.381 5.381c.342-.342.896-.342 1.237 0l6 6c.342.342.342.896 0 1.237l-6 6c-.342.342-.896.342-1.237 0s-.342-.896 0-1.237L13.763 12 8.381 6.619c-.342-.342-.342-.896 0-1.237z'/%3e%3c/svg%3e")) /* rtl: var(--cp-breadcrumb-divider, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23121422'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")) */;
}
.breadcrumb-item.active {
  color: var(--cp-breadcrumb-item-active-color);
}

.pagination {
  --cp-pagination-padding-x: 0;
  --cp-pagination-padding-y: 0;
  --cp-pagination-font-size: 1rem;
  --cp-pagination-color: #9396ad;
  --cp-pagination-bg: transparent;
  --cp-pagination-border-width: var(--cp-border-width);
  --cp-pagination-border-color: transparent;
  --cp-pagination-border-radius: 0;
  --cp-pagination-hover-color: #37394d;
  --cp-pagination-hover-bg: transparent;
  --cp-pagination-hover-border-color: transparent;
  --cp-pagination-focus-color: var(--cp-link-hover-color);
  --cp-pagination-focus-bg: transparent;
  --cp-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(18, 20, 34, 0.1);
  --cp-pagination-active-color: var(--cp-component-active-color);
  --cp-pagination-active-bg: transparent;
  --cp-pagination-active-border-color: transparent;
  --cp-pagination-disabled-color: var(--cp-secondary-color);
  --cp-pagination-disabled-bg: var(--cp-secondary-bg);
  --cp-pagination-disabled-border-color: var(--cp-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--cp-pagination-padding-y) var(--cp-pagination-padding-x);
  font-size: var(--cp-pagination-font-size);
  color: var(--cp-pagination-color);
  text-decoration: none;
  background-color: var(--cp-pagination-bg);
  border: var(--cp-pagination-border-width) solid var(--cp-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--cp-pagination-hover-color);
  background-color: var(--cp-pagination-hover-bg);
  border-color: var(--cp-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--cp-pagination-focus-color);
  background-color: var(--cp-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--cp-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--cp-pagination-active-color);
  background-color: var(--cp-pagination-active-bg);
  border-color: var(--cp-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--cp-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--cp-pagination-disabled-bg);
  border-color: var(--cp-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item .page-link {
  border-radius: var(--cp-pagination-border-radius);
}

.pagination-lg {
  --cp-pagination-padding-x: 0;
  --cp-pagination-padding-y: 0;
  --cp-pagination-font-size: 1.125rem;
  --cp-pagination-border-radius: var(--cp-border-radius-lg);
}

.pagination-sm {
  --cp-pagination-padding-x: 0;
  --cp-pagination-padding-y: 0;
  --cp-pagination-font-size: 0.875rem;
  --cp-pagination-border-radius: var(--cp-border-radius-sm);
}

.badge {
  --cp-badge-padding-x: 1em;
  --cp-badge-padding-y: 0.625em;
  --cp-badge-font-size: 0.75em;
  --cp-badge-font-weight: 500;
  --cp-badge-color: #fff;
  --cp-badge-border-radius: var(--cp-border-radius-lg);
  display: inline-block;
  padding: var(--cp-badge-padding-y) var(--cp-badge-padding-x);
  font-size: var(--cp-badge-font-size);
  font-weight: var(--cp-badge-font-weight);
  line-height: 1;
  color: var(--cp-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--cp-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--cp-primary-rgb), var(--cp-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--cp-secondary-rgb), var(--cp-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--cp-success-rgb), var(--cp-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--cp-info-rgb), var(--cp-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--cp-warning-rgb), var(--cp-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--cp-danger-rgb), var(--cp-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--cp-light-rgb), var(--cp-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--cp-dark-rgb), var(--cp-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--cp-primary-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-primary-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(92, 102, 239, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(92, 102, 239, var(--cp-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--cp-secondary-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-secondary-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(147, 150, 173, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(147, 150, 173, var(--cp-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--cp-success-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-success-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(51, 179, 107, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 179, 107, var(--cp-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--cp-info-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-info-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(92, 168, 239, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(92, 168, 239, var(--cp-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--cp-warning-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-warning-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(239, 180, 92, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(239, 180, 92, var(--cp-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--cp-danger-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-danger-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(239, 92, 127, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(239, 92, 127, var(--cp-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--cp-light-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-light-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(255, 255, 255, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--cp-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--cp-dark-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-dark-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(18, 20, 34, var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(18, 20, 34, var(--cp-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--cp-emphasis-color-rgb), var(--cp-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--cp-emphasis-color-rgb), var(--cp-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--cp-emphasis-color-rgb), var(--cp-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--cp-emphasis-color-rgb), var(--cp-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--cp-focus-ring-x, 0) var(--cp-focus-ring-y, 0) var(--cp-focus-ring-blur, 0) var(--cp-focus-ring-width) var(--cp-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--cp-link-color-rgb), var(--cp-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--cp-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--cp-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --cp-aspect-ratio: 100%;
}

.ratio-4x3 {
  --cp-aspect-ratio: 75%;
}

.ratio-16x9 {
  --cp-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --cp-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 500px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--cp-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--cp-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--cp-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--cp-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --cp-focus-ring-color: rgba(var(--cp-primary-rgb), var(--cp-focus-ring-opacity));
}

.focus-ring-secondary {
  --cp-focus-ring-color: rgba(var(--cp-secondary-rgb), var(--cp-focus-ring-opacity));
}

.focus-ring-success {
  --cp-focus-ring-color: rgba(var(--cp-success-rgb), var(--cp-focus-ring-opacity));
}

.focus-ring-info {
  --cp-focus-ring-color: rgba(var(--cp-info-rgb), var(--cp-focus-ring-opacity));
}

.focus-ring-warning {
  --cp-focus-ring-color: rgba(var(--cp-warning-rgb), var(--cp-focus-ring-opacity));
}

.focus-ring-danger {
  --cp-focus-ring-color: rgba(var(--cp-danger-rgb), var(--cp-focus-ring-opacity));
}

.focus-ring-light {
  --cp-focus-ring-color: rgba(var(--cp-light-rgb), var(--cp-focus-ring-opacity));
}

.focus-ring-dark {
  --cp-focus-ring-color: rgba(var(--cp-dark-rgb), var(--cp-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--cp-border-width) var(--cp-border-style) var(--cp-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--cp-border-width) var(--cp-border-style) var(--cp-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--cp-border-width) var(--cp-border-style) var(--cp-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--cp-border-width) var(--cp-border-style) var(--cp-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--cp-border-width) var(--cp-border-style) var(--cp-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-primary-rgb), var(--cp-border-opacity)) !important;
}

.border-secondary {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-secondary-rgb), var(--cp-border-opacity)) !important;
}

.border-success {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-success-rgb), var(--cp-border-opacity)) !important;
}

.border-info {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-info-rgb), var(--cp-border-opacity)) !important;
}

.border-warning {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-warning-rgb), var(--cp-border-opacity)) !important;
}

.border-danger {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-danger-rgb), var(--cp-border-opacity)) !important;
}

.border-light {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-light-rgb), var(--cp-border-opacity)) !important;
}

.border-dark {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-dark-rgb), var(--cp-border-opacity)) !important;
}

.border-black {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-black-rgb), var(--cp-border-opacity)) !important;
}

.border-white {
  --cp-border-opacity: 1;
  border-color: rgba(var(--cp-white-rgb), var(--cp-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--cp-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--cp-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--cp-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--cp-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--cp-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--cp-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--cp-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--cp-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --cp-border-opacity: 0.1;
}

.border-opacity-25 {
  --cp-border-opacity: 0.25;
}

.border-opacity-50 {
  --cp-border-opacity: 0.5;
}

.border-opacity-75 {
  --cp-border-opacity: 0.75;
}

.border-opacity-100 {
  --cp-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--cp-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: 1.25rem !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-primary-rgb), var(--cp-text-opacity)) !important;
}

.text-secondary {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-secondary-rgb), var(--cp-text-opacity)) !important;
}

.text-success {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-success-rgb), var(--cp-text-opacity)) !important;
}

.text-info {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-info-rgb), var(--cp-text-opacity)) !important;
}

.text-warning {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-warning-rgb), var(--cp-text-opacity)) !important;
}

.text-danger {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-danger-rgb), var(--cp-text-opacity)) !important;
}

.text-light {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-light-rgb), var(--cp-text-opacity)) !important;
}

.text-dark {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-dark-rgb), var(--cp-text-opacity)) !important;
}

.text-black {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-black-rgb), var(--cp-text-opacity)) !important;
}

.text-white {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-white-rgb), var(--cp-text-opacity)) !important;
}

.text-body {
  --cp-text-opacity: 1;
  color: rgba(var(--cp-body-color-rgb), var(--cp-text-opacity)) !important;
}

.text-muted {
  --cp-text-opacity: 1;
  color: var(--cp-secondary-color) !important;
}

.text-black-50 {
  --cp-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --cp-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --cp-text-opacity: 1;
  color: var(--cp-secondary-color) !important;
}

.text-body-tertiary {
  --cp-text-opacity: 1;
  color: var(--cp-tertiary-color) !important;
}

.text-body-emphasis {
  --cp-text-opacity: 1;
  color: var(--cp-emphasis-color) !important;
}

.text-reset {
  --cp-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --cp-text-opacity: 0.25;
}

.text-opacity-50 {
  --cp-text-opacity: 0.5;
}

.text-opacity-75 {
  --cp-text-opacity: 0.75;
}

.text-opacity-80 {
  --cp-text-opacity: 0.8;
}

.text-opacity-100 {
  --cp-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--cp-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--cp-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--cp-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--cp-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--cp-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--cp-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--cp-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--cp-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --cp-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --cp-link-opacity: 0.1;
}

.link-opacity-25 {
  --cp-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --cp-link-opacity: 0.25;
}

.link-opacity-50 {
  --cp-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --cp-link-opacity: 0.5;
}

.link-opacity-75 {
  --cp-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --cp-link-opacity: 0.75;
}

.link-opacity-100 {
  --cp-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --cp-link-opacity: 1;
}

.bg-primary {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-primary-rgb), var(--cp-bg-opacity)) !important;
}

.bg-secondary {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-secondary-rgb), var(--cp-bg-opacity)) !important;
}

.bg-success {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-success-rgb), var(--cp-bg-opacity)) !important;
}

.bg-info {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-info-rgb), var(--cp-bg-opacity)) !important;
}

.bg-warning {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-warning-rgb), var(--cp-bg-opacity)) !important;
}

.bg-danger {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-danger-rgb), var(--cp-bg-opacity)) !important;
}

.bg-light {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-light-rgb), var(--cp-bg-opacity)) !important;
}

.bg-dark {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-dark-rgb), var(--cp-bg-opacity)) !important;
}

.bg-black {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-black-rgb), var(--cp-bg-opacity)) !important;
}

.bg-white {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-white-rgb), var(--cp-bg-opacity)) !important;
}

.bg-body {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-body-bg-rgb), var(--cp-bg-opacity)) !important;
}

.bg-transparent {
  --cp-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-secondary-bg-rgb), var(--cp-bg-opacity)) !important;
}

.bg-body-tertiary {
  --cp-bg-opacity: 1;
  background-color: rgba(var(--cp-tertiary-bg-rgb), var(--cp-bg-opacity)) !important;
}

.bg-opacity-10 {
  --cp-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --cp-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --cp-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --cp-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --cp-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--cp-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--cp-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--cp-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--cp-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--cp-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--cp-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--cp-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--cp-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--cp-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--cp-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--cp-border-radius-xs) !important;
}

.rounded-2 {
  border-radius: var(--cp-border-radius-sm) !important;
}

.rounded-3 {
  border-radius: var(--cp-border-radius) !important;
}

.rounded-4 {
  border-radius: var(--cp-border-radius-lg) !important;
}

.rounded-5 {
  border-radius: var(--cp-border-radius-xl) !important;
}

.rounded-6 {
  border-radius: var(--cp-border-radius-xxl) !important;
}

.rounded-7 {
  border-radius: var(--cp-border-radius-xxxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--cp-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--cp-border-radius) !important;
  border-top-right-radius: var(--cp-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--cp-border-radius-sm) !important;
  border-top-right-radius: var(--cp-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--cp-border-radius) !important;
  border-top-right-radius: var(--cp-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--cp-border-radius-lg) !important;
  border-top-right-radius: var(--cp-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--cp-border-radius-xl) !important;
  border-top-right-radius: var(--cp-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--cp-border-radius-xxl) !important;
  border-top-right-radius: var(--cp-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--cp-border-radius-pill) !important;
  border-top-right-radius: var(--cp-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--cp-border-radius) !important;
  border-bottom-right-radius: var(--cp-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--cp-border-radius-sm) !important;
  border-bottom-right-radius: var(--cp-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--cp-border-radius) !important;
  border-bottom-right-radius: var(--cp-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--cp-border-radius-lg) !important;
  border-bottom-right-radius: var(--cp-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--cp-border-radius-xl) !important;
  border-bottom-right-radius: var(--cp-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--cp-border-radius-xxl) !important;
  border-bottom-right-radius: var(--cp-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--cp-border-radius-pill) !important;
  border-bottom-right-radius: var(--cp-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--cp-border-radius) !important;
  border-bottom-left-radius: var(--cp-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--cp-border-radius-sm) !important;
  border-bottom-left-radius: var(--cp-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--cp-border-radius) !important;
  border-bottom-left-radius: var(--cp-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--cp-border-radius-lg) !important;
  border-bottom-left-radius: var(--cp-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--cp-border-radius-xl) !important;
  border-bottom-left-radius: var(--cp-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--cp-border-radius-xxl) !important;
  border-bottom-left-radius: var(--cp-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--cp-border-radius-pill) !important;
  border-bottom-left-radius: var(--cp-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--cp-border-radius) !important;
  border-top-left-radius: var(--cp-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--cp-border-radius-sm) !important;
  border-top-left-radius: var(--cp-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--cp-border-radius) !important;
  border-top-left-radius: var(--cp-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--cp-border-radius-lg) !important;
  border-top-left-radius: var(--cp-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--cp-border-radius-xl) !important;
  border-top-left-radius: var(--cp-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--cp-border-radius-xxl) !important;
  border-top-left-radius: var(--cp-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--cp-border-radius-pill) !important;
  border-top-left-radius: var(--cp-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-100 {
  min-width: 100 !important;
}

@media (min-width: 500px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root,
[data-bs-theme=light] {
  --cp-component-color: #6a6e8b;
  --cp-component-hover-color: #121422;
  --cp-component-hover-bg: #eff2fd;
  --cp-component-active-color: #121422;
  --cp-component-active-bg: #eff2fd;
  --cp-component-disabled-color: #b4b7c9;
  --cp-user-selection-color: rgba(18, 20, 34, 0.1);
  --cp-border-radius-xs: calc(var(--cp-border-radius) * 0.5);
  --cp-border-radius-xxxl: calc(var(--cp-border-radius) * 3);
  --cp-underline-thickness: 1px;
  --plyr-color-main: #5c66ef;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html *:focus-visible {
  outline: var(--cp-focus-ring-width) solid var(--cp-focus-ring-color);
}

.offcanvas:focus-visible {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
}

.footer {
  flex-shrink: 0;
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  max-width: 100%;
}

iframe {
  width: 100%;
}

/* stylelint-disable selector-no-qualifying-type */
a,
button {
  text-underline-offset: 0.25em;
}
a.text-decoration-underline:hover,
button.text-decoration-underline:hover {
  text-decoration: none !important;
}

/* stylelint-enable selector-no-qualifying-type */
.link-body-emphasis {
  --cp-link-opacity: 1;
}

::selection {
  background: var(--cp-user-selection-color);
}

ol,
ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1.5rem;
}
ol ul,
ol ol,
ul ul,
ul ol {
  margin-top: 0.5rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  color: var(--cp-heading-color);
}

dd {
  margin-bottom: 0.75rem;
}

legend {
  color: var(--cp-heading-color);
}

[class*=" ci-"],
[class^=ci-] {
  display: inline-flex;
}
a [class*=" ci-"]:not(.animate-target), button [class*=" ci-"]:not(.animate-target),
a [class^=ci-]:not(.animate-target),
button [class^=ci-]:not(.animate-target) {
  transition: opacity 0.2s ease-in-out;
}
a:hover [class*=" ci-"]:not(.animate-target), a:focus-visible [class*=" ci-"]:not(.animate-target), a.active [class*=" ci-"]:not(.animate-target), a.show [class*=" ci-"]:not(.animate-target), button:hover [class*=" ci-"]:not(.animate-target), button:focus-visible [class*=" ci-"]:not(.animate-target), button.active [class*=" ci-"]:not(.animate-target), button.show [class*=" ci-"]:not(.animate-target),
a:hover [class^=ci-]:not(.animate-target),
a:focus-visible [class^=ci-]:not(.animate-target),
a.active [class^=ci-]:not(.animate-target),
a.show [class^=ci-]:not(.animate-target),
button:hover [class^=ci-]:not(.animate-target),
button:focus-visible [class^=ci-]:not(.animate-target),
button.active [class^=ci-]:not(.animate-target),
button.show [class^=ci-]:not(.animate-target) {
  opacity: 1 !important;
}

pre,
.hljs {
  padding: 1.25rem 1rem;
  margin: 0;
  background-color: #26283c;
  border-radius: var(--cp-border-radius);
}

kbd {
  border-radius: calc(var(--cp-border-radius) * 0.5);
}

.hljs-comment,
.hljs-quote {
  font-style: normal;
}

.container-start {
  margin-left: calc((100% - (1344px - 1.5rem)) / 2);
}

.container-end {
  margin-right: calc((100% - (1344px - 1.5rem)) / 2);
}

@media (max-width: 1399.98px) {
  .container-start {
    margin-left: calc((100% - (95% - 1.5rem)) / 2);
  }
  .container-end {
    margin-right: calc((100% - (95% - 1.5rem)) / 2);
  }
}
@media (max-width: 499.98px) {
  .container-start {
    padding-left: 1.5rem;
    margin-left: 0;
  }
  .container-end {
    padding-right: 1.5rem;
    margin-right: 0;
  }
}
#cc-main {
  --cc-padding-y: 1rem;
  --cc-padding-x: 1.5rem;
  --cc-font-family: var(--cp-body-font-family);
  --cc-modal-border-radius: calc(var(--cp-border-radius) * 2);
  --cc-secondary-color: #37394d;
  --cc-btn-border-radius: 0.625rem;
  --cc-btn-primary-bg: #5c66ef;
  --cc-btn-primary-border-color: #5c66ef;
  --cc-btn-primary-hover-bg: #8a92f4;
  --cc-btn-primary-hover-border-color: #8a92f4;
}
#cc-main .cm {
  max-width: 25rem;
  border: 1px solid #eff2fc;
  box-shadow: initial;
}
#cc-main .cm__texts {
  padding-top: var(--cc-padding-y);
}
#cc-main .cm__title,
#cc-main .cm__desc {
  padding-right: var(--cc-padding-x);
  padding-left: var(--cc-padding-x);
}
#cc-main .cm__title + .cm__desc {
  margin-top: 0.5rem;
}
#cc-main .cm__title {
  font-size: 1.25rem;
}
#cc-main .cm__desc {
  padding-bottom: initial;
  font-size: 0.875rem;
}
#cc-main .cm__btn-group {
  display: flex;
  gap: 0.5rem;
}
#cc-main .cm__btns {
  padding: var(--cc-padding-y) var(--cc-padding-x);
  border-top: initial;
}
#cc-main .cm__btn {
  padding: 0.75rem 1.5rem;
  margin: 0 !important;
  font-weight: 700;
}
#cc-main .cm__btn[data-role=necessary] {
  color: #121422;
  background-color: transparent;
  border-color: transparent;
}
#cc-main .cm__btn[data-role=necessary]:hover {
  color: #5c66ef;
}

/* stylelint-disable @stylistic/selector-list-comma-newline-after */
h1, .h1 {
  line-height: 1.2;
}

h2, .h2 {
  line-height: 1.25;
}

h3, .h3 {
  line-height: 1.3;
}

h4, .h4 {
  line-height: 1.35;
}

h5, .h5 {
  line-height: 1.35;
}

h6, .h6 {
  line-height: 1.35;
}

h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a,
h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  color: var(--cp-heading-color);
  text-decoration: none;
}

/* stylelint-enable @stylistic/selector-list-comma-newline-after */
.blockquote {
  font-weight: 600;
  color: var(--cp-heading-color);
}

.blockquote-footer {
  margin-top: -0.75rem;
}

.list-inline {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem 1rem;
  margin-bottom: 0.75rem;
}

.btn {
  --cp-btn-active-border-color: transparent;
  --cp-btn-disabled-border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(18, 20, 34, 0.1);
}

.btn-primary {
  --cp-btn-hover-bg: #8a92f4;
  --cp-btn-active-bg: #8a92f4;
  --cp-btn-hover-border-color: #8a92f4;
  --cp-btn-active-border-color: #8a92f4;
}

.btn-success {
  --cp-btn-hover-bg: #4dcc85;
  --cp-btn-active-bg: #4dcc85;
  --cp-btn-hover-border-color: #4dcc85;
  --cp-btn-active-border-color: #4dcc85;
}

.btn-info {
  --cp-btn-hover-bg: #8ac1f4;
  --cp-btn-active-bg: #8ac1f4;
  --cp-btn-hover-border-color: #8ac1f4;
  --cp-btn-active-border-color: #8ac1f4;
}

.btn-warning {
  --cp-btn-hover-bg: #f4c98a;
  --cp-btn-active-bg: #f4c98a;
  --cp-btn-hover-border-color: #f4c98a;
  --cp-btn-active-border-color: #f4c98a;
}

.btn-danger {
  --cp-btn-hover-bg: #f48aa3;
  --cp-btn-active-bg: #f48aa3;
  --cp-btn-hover-border-color: #f48aa3;
  --cp-btn-active-border-color: #f48aa3;
}

.btn-dark {
  --cp-btn-bg: #121422;
  --cp-btn-border-color: #121422;
}

.btn-light {
  --cp-btn-hover-color: #fff;
  --cp-btn-hover-bg: #5c66ef;
  --cp-btn-hover-border-color: #5c66ef;
  --cp-btn-active-color: #fff;
  --cp-btn-active-bg: #5c66ef;
  --cp-btn-hover-border-color: #5c66ef;
}

.btn-outline-secondary {
  --cp-btn-color: #121422;
  --cp-btn-hover-color: #121422;
  --cp-btn-active-color: #121422;
  --cp-btn-bg: transparent;
  --cp-btn-hover-bg: #fff;
  --cp-btn-active-bg: #fff;
  --cp-btn-border-color: #e2e5f1;
  --cp-btn-hover-border-color: #e2e5f1;
  --cp-btn-active-border-color: #e2e5f1;
}

.btn-icon {
  --cp-btn-size: 2.5rem;
  flex-shrink: 0;
  width: var(--cp-btn-size);
  height: var(--cp-btn-size);
  padding: 0;
}
.btn-icon.btn-lg {
  --cp-btn-size: 3rem;
}
.btn-icon.btn-sm {
  --cp-btn-size: 2rem;
}

.btn-coin {
  --cp-btn-padding-y: 0.5rem;
  --cp-btn-padding-x: 0.5rem;
  --cp-btn-color: #121422;
  --cp-btn-hover-color: #121422;
  --cp-btn-active-color: #121422;
  --cp-btn-bg: #fff;
  --cp-btn-hover-bg: #fff;
  --cp-btn-active-bg: #fff;
  --cp-btn-border-color: #fff;
  --cp-btn-hover-border-color: #e2e5f1;
  --cp-btn-active-border-color: #e2e5f1;
  --cp-btn-border-radius: 50rem;
  gap: 0.75rem;
  justify-content: space-between;
  text-align: left;
  text-wrap: wrap;
}
.btn-coin:hover .btn-coin-icon, .btn-coin.active .btn-coin-icon {
  opacity: 1;
}

.btn-coin-icon {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
@media (max-width: 991.98px) {
  .btn-coin-icon {
    display: none;
  }
}

.form-control,
.form-select {
  --cp-form-control-bg: #fff;
  --cp-form-control-border-color: #e2e5f1;
  --cp-form-control-focus-bg: #fff;
  --cp-form-control-focus-border-color: #5c66ef;
  background-color: var(--cp-form-control-bg);
  border-color: var(--cp-form-control-border-color);
}
.form-control:focus,
.form-select:focus {
  background-color: var(--cp-form-control-focus-bg);
  border-color: var(--cp-form-control-focus-border-color);
  box-shadow: 0 0 0 3px rgba(92, 102, 239, 0.2);
}
.form-control:disabled,
.form-select:disabled {
  border-style: dashed;
}

.form-check-label {
  font-size: 0.875rem;
}

.form-check-input:focus {
  box-shadow: none;
}
.form-check-input:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(18, 20, 34, 0.1);
}
.form-check-input:active {
  background-color: transparent;
  filter: none;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--cp-form-control-border-color);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--cp-form-control-focus-border-color);
}

.was-validated .password-toggle .form-control:valid,
.password-toggle .form-control.is-valid,
.was-validated .password-toggle .form-control:invalid,
.password-toggle .form-control.is-invalid {
  background-position: center right 2.25rem;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: var(--cp-border-width) solid #b4b7c9;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #5c66ef;
  border-color: #5c66ef;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: none;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--cp-body-color);
}

.was-validated .form-switch .form-check-input:invalid,
.form-switch .form-check-input.is-invalid {
  background-color: var(--cp-form-invalid-color) !important;
}

.valid-tooltip {
  color: var(--cp-form-valid-color);
  background-color: var(--cp-success-bg-subtle);
}

.invalid-tooltip {
  color: var(--cp-form-invalid-color);
  background-color: var(--cp-danger-bg-subtle);
}

.choices {
  margin-bottom: 0;
}

.choices[data-type*=select-one]::after {
  display: none;
}
.choices[data-type*=select-one] .form-select {
  padding-right: 3.25rem;
}
.choices[data-type*=select-one] .choices__button {
  right: 0;
  left: auto;
  margin-right: 2rem;
  margin-left: 0;
  background-size: 9px;
  box-shadow: none !important;
  opacity: 0.4;
  transition: opacity 0.15s ease-in-out;
}
.choices[data-type*=select-one] .choices__button:hover {
  opacity: 0.8;
}

.filter-select:has(.choices__item:not(.choices__placeholder)) {
  --cp-form-control-border-color: #5c66ef;
}

.choices[data-type*=select-multiple] .form-select,
.choices[data-type*=text] .form-select {
  padding: 1rem 1rem 0.75rem 1rem;
  background-image: none;
}
.choices[data-type*=select-multiple] .form-select.form-select-lg,
.choices[data-type*=text] .form-select.form-select-lg {
  padding: 1.25rem 1.25rem 1rem 1.25rem;
}
.choices[data-type*=select-multiple] .form-select.form-select-sm,
.choices[data-type*=text] .form-select.form-select-sm {
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}

.choices__placeholder {
  color: var(--cp-tertiary-color);
  opacity: 1;
}

.is-focused .form-select {
  border-color: var(--cp-form-control-focus-border-color);
}

.is-disabled .form-select {
  color: var(--cp-tertiary-color);
  background-color: var(--cp-tertiary-bg);
  border-color: var(--cp-border-color);
  border-style: dashed;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  z-index: 10;
  padding: 1rem 1rem;
  margin: 0.3125rem 0 !important;
  font-size: 1rem;
  background-color: var(--cp-body-bg);
  border: var(--cp-border-width) solid var(--cp-light-border-subtle) !important;
  border-radius: var(--cp-border-radius-xl) !important;
  box-shadow: var(--cp-box-shadow) !important;
}
.choices__list--dropdown .choices__placeholder,
.choices__list[aria-expanded] .choices__placeholder {
  display: none !important;
}
.choices__list--dropdown .choices__list,
.choices__list[aria-expanded] .choices__list {
  max-height: 260px;
}

.choices__list--single {
  display: flex;
  padding: 0;
}
.choices__list--single .choices__item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #121422;
  border-radius: 0.5rem !important;
}
.choices__list--dropdown .choices__item.is-highlighted,
.choices__list[aria-expanded] .choices__item.is-highlighted {
  color: #121422;
  background-color: var(--cp-component-active-bg);
}

.choices .choices__input {
  padding-top: 0.25rem;
  padding-right: 0;
  padding-bottom: 0.25rem;
  padding-left: 0.375rem;
  margin: 0;
  margin-bottom: 0.25rem;
  color: #121422;
  background-color: transparent !important;
}
.choices .choices__input::placeholder {
  color: var(--cp-tertiary-color);
  opacity: 1;
}

.choices:not([data-type*=select-multiple]):not([data-type*=text]) .choices__input {
  padding-left: 1.75rem !important;
  margin-top: -0.375rem !important;
  margin-bottom: 0.3125rem !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 32 32' fill='%239ca3af'%3e%3cpath d='m21.6 20.4-3.8-3.8c1.2-1.5 2-3.5 2-5.6 0-4.9-4-8.9-8.9-8.9S2 6.1 2 11s4 8.9 8.9 8.9c2.1 0 4.1-.8 5.6-2l3.8 3.8c.3.3.9.3 1.2 0 .5-.4.5-1 .1-1.3M16.1 16l-.1.1c-1.3 1.2-3 2-5 2-3.9 0-7.1-3.2-7.1-7.1S7.1 3.9 11 3.9s7.1 3.2 7.1 7.1c0 1.9-.8 3.7-2 5'/%3e%3c/svg%3e") no-repeat 0.125rem 0.75rem;
  border-color: var(--cp-border-color) !important;
}

.choices__list--dropdown .choices__item--selectable::after,
.choices__list[aria-expanded] .choices__item--selectable::after {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.1875rem;
  margin-right: -0.25rem;
  margin-left: auto;
  content: "";
  background-color: currentcolor;
  opacity: 0;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M20.619 5.381a.875.875 0 0 1 0 1.238l-11 11a.875.875 0 0 1-1.238 0l-5-5A.875.875 0 1 1 4.62 11.38L9 15.763 19.381 5.38a.875.875 0 0 1 1.238 0Z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M20.619 5.381a.875.875 0 0 1 0 1.238l-11 11a.875.875 0 0 1-1.238 0l-5-5A.875.875 0 1 1 4.62 11.38L9 15.763 19.381 5.38a.875.875 0 0 1 1.238 0Z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  opacity: 0.85;
}

.choices__heading {
  padding: 1rem 1rem 0.75rem;
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--cp-heading-color);
  border-color: var(--cp-border-color);
}

.choices.is-disabled .choices__list--multiple .choices__item,
.choices__list--multiple .choices__item {
  background-color: var(--cp-emphasis-color);
  border-color: var(--cp-emphasis-color);
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}
.choices.is-disabled .choices__list--multiple .choices__item .choices__button,
.choices__list--multiple .choices__item .choices__button {
  padding-right: 0.375rem;
  padding-left: 1rem;
  margin-right: -0.25rem;
  margin-left: 0.5rem;
  border-right: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.choices.is-disabled .choices__list--multiple .choices__item.is-highlighted,
.choices__list--multiple .choices__item.is-highlighted {
  background-color: var(--cp-secondary-color);
  border-color: var(--cp-secondary-color);
}

.choices.is-disabled .choices__list--multiple .choices__item {
  opacity: 0.45;
}

.was-validated .choices:has(.form-select:invalid) .form-select,
.choices:has(.is-invalid) .form-select {
  border-color: var(--cp-form-invalid-border-color);
}
.was-validated .choices:has(.form-select:invalid) ~ .invalid-feedback,
.was-validated .choices:has(.form-select:invalid) ~ .invalid-tooltip,
.choices:has(.is-invalid) ~ .invalid-feedback,
.choices:has(.is-invalid) ~ .invalid-tooltip {
  display: block;
}

.was-validated .choices:has(.form-select:valid) ~ .valid-feedback,
.was-validated .choices:has(.form-select:valid) ~ .valid-tooltip,
.choices:has(.is-valid) ~ .valid-feedback,
.choices:has(.is-valid) ~ .valid-tooltip {
  display: block;
}

.icon-box {
  --cp-icon-box-size: 2.75rem;
  --cp-icon-box-font-size: 1.25rem;
  --cp-icon-box-font-weight: 700;
  --cp-icon-box-border-radius: 50%;
  --cp-icon-box-color: #fff;
  --cp-icon-box-bg: #121422;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--cp-icon-box-size);
  height: var(--cp-icon-box-size);
  font-size: var(--cp-icon-box-font-size);
  font-weight: var(--cp-icon-box-font-weight);
  color: var(--cp-icon-box-color);
  text-align: center;
  background-color: var(--cp-icon-box-bg);
  border-radius: var(--cp-icon-box-border-radius);
}

.masonry-grid[data-columns] {
  overflow: hidden;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.masonry-grid[data-columns]::after {
  display: block;
  clear: both;
  content: "";
}
.masonry-grid[data-columns] .masonry-grid-item {
  float: left;
  width: 100%;
  padding-right: 0.75rem;
  padding-bottom: 1.5rem;
  padding-left: 0.75rem;
}
@media (min-width: 992px) {
  .masonry-grid[data-columns].grid-gutter-5 {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .masonry-grid[data-columns].grid-gutter-5 .masonry-grid-item {
    padding-right: 1.5rem;
    padding-bottom: 3rem;
    padding-left: 1.5rem;
  }
}
.masonry-grid[data-columns].grid-gutter-3 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.masonry-grid[data-columns].grid-gutter-3 .masonry-grid-item {
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
}

.masonry-grid[data-columns="1"] .masonry-grid-item {
  width: 100%;
}

@media (min-width: 500px) {
  .masonry-grid[data-columns="2"] .masonry-grid-item,
  .masonry-grid[data-columns="3"] .masonry-grid-item,
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 33.333%;
  }
}
@media (min-width: 992px) {
  .masonry-grid[data-columns="3"] .masonry-grid-item,
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 33.333%;
  }
}
@media (min-width: 1200px) {
  .masonry-grid[data-columns="4"] .masonry-grid-item,
  .masonry-grid[data-columns="5"] .masonry-grid-item,
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 25%;
  }
}
@media (min-width: 1400px) {
  .masonry-grid[data-columns="5"] .masonry-grid-item {
    width: 20%;
  }
  .masonry-grid[data-columns="6"] .masonry-grid-item {
    width: 16.666%;
  }
}
.swiper-button-lock {
  display: none !important;
}

.swiper-pagination-bullets {
  --swiper-pagination-bottom: 1.5rem;
  --swiper-pagination-right: 1.5rem;
  --swiper-pagination-bullet-inactive-color: #e2e5f1;
  --swiper-pagination-color: #26283c;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 0.375rem;
  --swiper-pagination-bullet-vertical-gap: 0.375rem;
  --swiper-pagination-bullet-transition: background-color 0.2s ease-in-out;
}

.swiper-pagination-bullet {
  transition: var(--swiper-pagination-bullet-transition);
}

.swiper-pagination-progressbar {
  --swiper-pagination-progressbar-size: 0.25rem;
  --swiper-pagination-progressbar-border-radius: 50rem;
  --swiper-pagination-progressbar-bg-color: #eff2fd;
  --swiper-pagination-color: #26283c;
  overflow: hidden;
  border-radius: var(--swiper-pagination-progressbar-border-radius);
}

.swiper-load {
  visibility: hidden;
}
.swiper-load.swiper-initialized {
  visibility: visible;
}

.swiper-ticker {
  overflow: hidden;
  pointer-events: none;
}
.swiper-ticker .swiper-wrapper {
  transition-timing-function: linear;
}
.swiper-ticker .swiper-slide {
  width: auto;
}

.zoom-effect-wrapper {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.zoom-effect-img {
  display: block;
  transition: transform 0.4s ease-in-out;
}

.zoom-effect:hover .zoom-effect-img {
  transform: translateZ(0) scale(1.05);
}

.accordion-button {
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-end;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.accordion-button::after {
  margin-top: 0.125em;
  margin-right: 0.9375rem;
  margin-left: 0;
}
.accordion-button.collapsed:hover::after {
  transform: rotate(180deg);
}

.accordion-body {
  padding-top: 0;
  padding-left: 2.1875rem;
}

.accordion-light {
  --cp-accordion-color: #fff;
  --cp-accordion-active-color: #fff;
  --cp-accordion-btn-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'><path fill-rule='evenodd' d='M7.999.667c.368 0 .667.298.667.667v6h6c.368 0 .667.298.667.667s-.299.667-.667.667h-6v6c0 .368-.298.667-.667.667s-.667-.299-.667-.667v-6h-6C.964 8.667.666 8.369.666 8s.298-.667.667-.667h6v-6c0-.368.298-.667.667-.667z' fill='#fff'/></svg>");
  --cp-accordion-btn-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'><path fill-rule='evenodd' d='M.666 8c0-.368.298-.667.667-.667v1.333C.964 8.666.666 8.368.666 8zm14 0v.667H1.333V7.333h13.333V8zm0 0v.667c.368 0 .667-.298.667-.667s-.299-.667-.667-.667V8z' fill='#fff'/></svg>");
}

.nav {
  --cp-nav-link-line-height: 1.375rem;
  --cp-nav-link-active-color: var(--cp-component-active-color);
  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;
}
.nav-link.show {
  color: var(--cp-nav-link-hover-color);
}
.nav-link.active {
  color: var(--cp-nav-link-active-color);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--cp-nav-link-disabled-color);
}
.nav-link.text-body:hover, .nav-link.text-body:focus-visible, .nav-link.text-body.show, .nav-link.text-body-secondary:hover, .nav-link.text-body-secondary:focus-visible, .nav-link.text-body-secondary.show, .nav-link.text-body-tertiary:hover, .nav-link.text-body-tertiary:focus-visible, .nav-link.text-body-tertiary.show {
  color: var(--cp-nav-link-hover-color) !important;
}
.nav-link.text-body.active, .nav-link.text-body-secondary.active, .nav-link.text-body-tertiary.active {
  color: var(--cp-nav-link-active-color) !important;
}
.nav-link.text-body.disabled, .nav-link.text-body:disabled, .nav-link.text-body-secondary.disabled, .nav-link.text-body-secondary:disabled, .nav-link.text-body-tertiary.disabled, .nav-link.text-body-tertiary:disabled {
  color: var(--cp-nav-link-disabled-color) !important;
}

.nav-page .nav-link::before {
  display: none;
  flex-shrink: 0;
  align-self: flex-start;
  width: 1em;
  height: 1em;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3e%3cpath fill-rule='evenodd' d='M5.843 2.823l-.707.707 4.313 4.313-4.313 4.313.707.707 5.02-5.02-5.02-5.02z' fill='%23121422'/%3e%3c/svg%3e");
  content: "";
}
.nav-page .nav-link.active::before {
  display: inline-block;
}

.navbar {
  background-color: rgba(255, 255, 255, 0.85);
}
.navbar.bg-blur {
  backdrop-filter: blur(20px);
}
.navbar.sticky-top {
  z-index: 1031;
}

.navbar-toggler {
  position: relative;
  width: 1.875rem;
  height: 2.25rem;
}

.navbar-toggler-icon {
  display: block;
  top: 50%;
  margin-top: -0.0625rem;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.navbar-toggler-icon, .navbar-toggler-icon::before, .navbar-toggler-icon::after {
  position: absolute;
  width: 1.375rem;
  height: 0.125rem;
  transition-property: transform;
  background-color: #121422;
}
.navbar-toggler-icon::before, .navbar-toggler-icon::after {
  display: block;
  width: 1.375rem;
  content: "";
}
.navbar-toggler-icon:after {
  right: 0;
}
.navbar-toggler-icon::before {
  top: -0.4375rem;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease, width 0.15s ease-in-out;
}
.navbar-toggler-icon::after {
  bottom: -0.4375rem;
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.15s ease-in-out;
}

.navbar-toggler:hover .navbar-toggler-icon, .navbar-toggler:hover .navbar-toggler-icon::before, .navbar-toggler:hover .navbar-toggler-icon::after {
  width: 1.375rem;
}

[aria-expanded=true] .navbar-toggler-icon {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
[aria-expanded=true] .navbar-toggler-icon::before, [aria-expanded=true] .navbar-toggler-icon::after {
  width: 1.375rem;
}
[aria-expanded=true] .navbar-toggler-icon::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
[aria-expanded=true] .navbar-toggler-icon::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.navbar-dark .navbar-toggler-icon, .navbar-dark .navbar-toggler-icon:after, .navbar-dark .navbar-toggler-icon:before {
  background-color: #fff;
}

.navbar-nav .nav-link {
  font-size: 1rem;
}
@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    font-size: 1.25rem;
  }
}

.pagination {
  --cp-pagination-spacer: 0;
  --cp-pagination-size: 2.75rem;
  --cp-pagination-font-weight: 700;
  flex-direction: row;
  gap: var(--cp-pagination-spacer);
  margin-bottom: 0;
}

.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--cp-pagination-size);
  height: var(--cp-pagination-size);
  font-weight: var(--cp-pagination-font-weight);
}

.pagination-sm {
  --cp-pagination-size: 2rem;
}

.pagination-lg {
  --cp-pagination-size: 3rem;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}
.dropdown-toggle::after {
  flex-shrink: 0;
  width: 1.275em;
  height: 1.275em;
  margin-right: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.dropup .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}

.dropend .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}

[dir=rtl] .dropend .dropdown-toggle::after {
  transform: rotate(180deg);
}

.dropstart .dropdown-toggle::before {
  flex-shrink: 0;
  width: 1.275em;
  height: 1.275em;
  margin-left: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--cp-dropdown-item-spacer);
  font-weight: 500;
  border-radius: 0.5rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.dropdown-item.show, [data-bs-toggle].show > .dropdown-item, [data-bs-toggle]:hover > .dropdown-item, .dropdown-item:active {
  color: var(--cp-dropdown-link-hover-color);
  background-color: var(--cp-dropdown-link-hover-bg);
}
.dropdown-item:focus-visible {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(18, 20, 34, 0.1);
}
.dropdown-item.dropdown-toggle::after {
  margin-left: auto;
}

.breadcrumb {
  --cp-breadcrumb-font-weight: 700;
  --cp-breadcrumb-color: #121422;
  --cp-breadcrumb-hover-color: #5c66ef;
  flex-direction: row;
  gap: 0;
  font-weight: var(--cp-breadcrumb-font-weight);
}

.breadcrumb-item > a {
  color: var(--cp-breadcrumb-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
.breadcrumb-item > a:hover, .breadcrumb-item > a:focus-visible {
  color: var(--cp-breadcrumb-hover-color);
}
.breadcrumb-item + .breadcrumb-item::before {
  width: 1.125em;
  height: 1.125em;
  padding-right: 0;
  margin-right: var(--cp-breadcrumb-item-padding-x);
  line-height: 2em;
}

/*# sourceMappingURL=styles.css.map */
