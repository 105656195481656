// Navbar

.navbar {
  background-color: rgba($white, .85);

  &.bg-blur {
    backdrop-filter: blur(20px);
  }

  &.sticky-top {
    z-index: $zindex-fixed + 1;
  }
}


// Toggler

// stylelint-disable
.navbar-toggler {
  position: relative;
  width: $navbar-toggler-bar-width + $navbar-toggler-padding-x * 2;
  height: $navbar-toggler-bar-height * 3 + $navbar-toggler-bar-spacing * 2 + $navbar-toggler-padding-y * 2;
}

.navbar-toggler-icon {
  display: block;
  top: 50%;
  margin-top: $navbar-toggler-bar-height * -.5;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &, &::before, &::after {
    position: absolute;
    width: $navbar-toggler-bar-width;
    height: $navbar-toggler-bar-height;
    transition-property: transform;
    background-color: $gray-900;
  }
  &::before, &::after {
    display: block;
    width: $navbar-toggler-bar-width;
    content: '';
  }
  &:after {
    right: 0;
  }
  &::before {
    top: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease, width .15s ease-in-out;
  }
  &::after {
    bottom: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), width .15s ease-in-out;
  }
}

.navbar-toggler:hover .navbar-toggler-icon {
  &, &::before, &::after {
    width: $navbar-toggler-bar-width;
  }
}

[aria-expanded=true] {
  .navbar-toggler-icon {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before, &::after {
      width: $navbar-toggler-bar-width;
    }
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}

// Light variant

.navbar-dark {
  .navbar-toggler-icon {
    &, &:after, &:before {
      background-color: $white;
    }
  }
}
// stylelint-enable


// Navbar nav

.navbar-nav {
  .nav-link {
    font-size: $navbar-nav-link-font-size;
    @include media-breakpoint-down(lg) {
      font-size: $font-size-xl;
    }
  }
}
