// Extend default Bootstrap's .nav, .nav-item, .nav-link classes

.nav {
  --#{$prefix}nav-link-line-height: #{$nav-link-line-height};
  --#{$prefix}nav-link-active-color: #{$nav-link-active-color};

  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;

  &.show {
    color: var(--#{$prefix}nav-link-hover-color);
  }

  &.active {
    color: var(--#{$prefix}nav-link-active-color);
  }

  &.disabled,
  &:disabled {
    color: var(--#{$prefix}nav-link-disabled-color);
  }

  &.text-body,
  &.text-body-secondary,
  &.text-body-tertiary {
    &:hover,
    &:focus-visible,
    &.show {
      color: var(--#{$prefix}nav-link-hover-color) !important;
    }
    &.active {
      color: var(--#{$prefix}nav-link-active-color) !important;
    }
    &.disabled,
    &:disabled {
      color: var(--#{$prefix}nav-link-disabled-color) !important;
    }
  }
}


// Page nav (quick-links)

.nav-page {
  .nav-link {
    &::before {
      display: none;
      flex-shrink: 0;
      align-self: flex-start;
      width: 1em;
      height: 1em;
      margin: {
        top: $spacer * .25;
        right: $spacer * .25;
      }
      background: {
        position: center;
        size: contain;
        repeat: no-repeat;
        image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3e%3cpath fill-rule='evenodd' d='M5.843 2.823l-.707.707 4.313 4.313-4.313 4.313.707.707 5.02-5.02-5.02-5.02z' fill='%23121422'/%3e%3c/svg%3e");
      }
      content: "";
    }

    &.active::before {
      display: inline-block;
    }
  }
}
