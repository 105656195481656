// Image hover effects utilities


// Zoom image effect

.zoom-effect-wrapper {
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.zoom-effect-img {
  display: block;
  transition: transform .4s ease-in-out;
}

.zoom-effect:hover .zoom-effect-img {
  transform: translateZ(0) scale(1.05);
}
