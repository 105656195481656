// Carousel (slider) component
// based on https://swiperjs.com/


// Pagination

// Navigation (Prev/next buttons)

.swiper-button-lock {
  display: none !important;
}


// Bullets

.swiper-pagination-bullets {
  --swiper-pagination-bottom: 1.5rem;
  --swiper-pagination-right: 1.5rem;
  --swiper-pagination-bullet-inactive-color: #{$carousel-bullet-bg};
  --swiper-pagination-color: #{$carousel-bullet-active-bg};
  --swiper-pagination-bullet-border-radius: #{$carousel-bullet-border-radius};
  --swiper-pagination-bullet-inactive-opacity: #{$carousel-bullet-opacity};
  --swiper-pagination-bullet-opacity: #{$carousel-bullet-active-opacity};
  --swiper-pagination-bullet-horizontal-gap: #{$carousel-bullet-gap * .5};
  --swiper-pagination-bullet-vertical-gap: #{$carousel-bullet-gap * .5};
  --swiper-pagination-bullet-transition: #{$carousel-bullet-transition};
}

.swiper-pagination-bullet {
  transition: var(--swiper-pagination-bullet-transition);
}


// Progress bar

.swiper-pagination-progressbar {
  --swiper-pagination-progressbar-size: #{$carousel-progressbar-size};
  --swiper-pagination-progressbar-border-radius: #{$carousel-progressbar-border-radius};
  --swiper-pagination-progressbar-bg-color: #{$carousel-progressbar-bg};
  --swiper-pagination-color: #{$carousel-progressbar-fill-bg};

  overflow: hidden;
  @include border-radius(var(--swiper-pagination-progressbar-border-radius));
}


// Load swiper nicely

.swiper-load {
  visibility: hidden;

  &.swiper-initialized {
    visibility: visible;
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {
    .swiper-pagination-bullets:not([data-bs-theme="light"]) {
      --swiper-pagination-bullet-inactive-color: #{$carousel-bullet-bg-dark};
      --swiper-pagination-color: #{$carousel-bullet-active-bg-dark};
    }
    .swiper-pagination-progressbar:not([data-bs-theme="light"]) {
      --swiper-pagination-progressbar-bg-color: #{$carousel-progressbar-bg-dark};
      --swiper-pagination-color: #{$carousel-progressbar-fill-bg-dark};
    }
    .swiper-scrollbar:not([data-bs-theme="light"]) {
      --swiper-scrollbar-bg-color: #{$carousel-scrollbar-bg-dark};
      --swiper-scrollbar-drag-bg-color: #{$carousel-scrollbar-drag-bg-dark};
    }
  }
}


// Ticker slider (smoove infinite loop)

.swiper-ticker {
  overflow: hidden;
  pointer-events: none;

  .swiper-wrapper {
    transition-timing-function: linear;
  }

  .swiper-slide {
    width: auto;
  }
}
