// Icon box component

.icon-box {
  --#{$prefix}icon-box-size: #{$icon-box-size};
  --#{$prefix}icon-box-font-size: #{$icon-box-font-size};
  --#{$prefix}icon-box-font-weight: #{$icon-box-font-weight};
  --#{$prefix}icon-box-border-radius: #{$icon-box-border-radius};
  --#{$prefix}icon-box-color: #{$icon-box-color};
  --#{$prefix}icon-box-bg: #{$icon-box-bg};

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--#{$prefix}icon-box-size);
  height: var(--#{$prefix}icon-box-size);
  font-size: var(--#{$prefix}icon-box-font-size);
  font-weight: var(--#{$prefix}icon-box-font-weight);
  color: var(--#{$prefix}icon-box-color);
  text-align: center;
  background-color: var(--#{$prefix}icon-box-bg);
  @include border-radius(var(--#{$prefix}icon-box-border-radius));
}
