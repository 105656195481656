// Vanilla cokie modal styles
// based on https://github.com/orestbida/cookieconsent


// stylelint-disable selector-max-id, selector-no-qualifying-type, selector-class-pattern, declaration-no-important
#cc-main {


  // General

  $lib-prefix: cc-;

  --#{$lib-prefix}padding-y: #{$spacer};
  --#{$lib-prefix}padding-x: #{$spacer * 1.5};
  --#{$lib-prefix}font-family: var(--cp-body-font-family);
  --#{$lib-prefix}modal-border-radius: #{$border-radius-xl};
  --#{$lib-prefix}secondary-color: #{$body-color};

  .cm {
    max-width: 25rem;
    border: $border-width solid $border-color;
    box-shadow: initial;
  }


  // Padding & spacings

  .cm__texts {
    padding-top: var(--#{$lib-prefix}padding-y);
  }
  .cm__title,
  .cm__desc {
    padding: {
      right: var(--#{$lib-prefix}padding-x);
      left: var(--#{$lib-prefix}padding-x);
    }
  }
  .cm__title + .cm__desc {
    margin-top: $spacer * .5;
  }

  .cm__title {
    font-size: $h5-font-size;
  }
  .cm__desc {
    padding-bottom: initial;
    font-size: $font-size-sm;
  }
  .cm__btn-group {
    display: flex;
    gap: $spacer * .5;
  }


  // Buttons

  --#{$lib-prefix}btn-border-radius: #{$btn-border-radius-sm};
  --#{$lib-prefix}btn-primary-bg: #{$primary};
  --#{$lib-prefix}btn-primary-border-color: #{$primary};
  --#{$lib-prefix}btn-primary-hover-bg: #{lighten($primary, 10%)};
  --#{$lib-prefix}btn-primary-hover-border-color: #{lighten($primary, 10%)};

  .cm__btns {
    padding: var(--#{$lib-prefix}padding-y) var(--#{$lib-prefix}padding-x);
    border-top: initial;
  }

  .cm__btn {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    margin: 0 !important;
    font-weight: $btn-font-weight;

    &[data-role="necessary"] {
      color: $btn-link-color;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        color: $btn-link-hover-color;
      }
    }
  }
}
// stylelint-enable selector-max-id, selector-no-qualifying-type, selector-class-pattern, declaration-no-important
