// Extend default Bootstrap's .form-control / .form-select classes

.form-control,
.form-select {
  --#{$prefix}form-control-bg: #{$input-bg};
  --#{$prefix}form-control-border-color: #{$input-border-color};
  --#{$prefix}form-control-focus-bg: #{$input-focus-bg};
  --#{$prefix}form-control-focus-border-color: #{$input-focus-border-color};

  background-color: var(--#{$prefix}form-control-bg);
  border-color: var(--#{$prefix}form-control-border-color);

  &:focus {
    background-color: var(--#{$prefix}form-control-focus-bg);
    border-color: var(--#{$prefix}form-control-focus-border-color);
    box-shadow: 0 0 0 3px rgba(92, 102, 239, .2);
  }

  &:disabled {
    border-style: dashed;
  }
}


// Checkboxes and radios

.form-check-label {
  font-size: $form-label-font-size;
}

.form-check-input {
  &:focus {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: $focus-ring-box-shadow;
  }

  &:active {
    background-color: transparent;
    filter: none;
  }
}


// Form validation

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--#{$prefix}form-control-border-color);

  &:focus {
    border-color: var(--#{$prefix}form-control-focus-border-color);
  }
}

.was-validated .password-toggle .form-control:valid,
.password-toggle .form-control.is-valid,
.was-validated .password-toggle .form-control:invalid,
.password-toggle .form-control.is-invalid {
  background-position: $form-select-feedback-icon-position;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: $form-check-input-border;

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;
  }

  &:focus {
    box-shadow: none;
  }

  ~ .form-check-label {
    color: var(--#{$prefix}body-color);
  }
}

.was-validated .form-switch .form-check-input:invalid,
.form-switch .form-check-input.is-invalid {
  background-color: var(--#{$prefix}form-invalid-color) !important;
}

.valid-tooltip {
  color: var(--#{$prefix}form-valid-color);
  background-color: var(--#{$prefix}success-bg-subtle);
}

.invalid-tooltip {
  color: var(--#{$prefix}form-invalid-color);
  background-color: var(--#{$prefix}danger-bg-subtle);
}
