// Utilities
// Extend / update default Bootstrap's utility classes

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/* stylelint-disable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines */
$utilities: map-merge(
  $utilities,
  (
    // Remove utilities
    "link-offset": null,
    "link-underline": null,
    "link-underline-opacity": null,

    // Border radius
    "rounded": (
      property: border-radius,
      class: rounded,
      values: (
        null: var(--#{$prefix}border-radius),
        0: 0,
        1: var(--#{$prefix}border-radius-xs),
        2: var(--#{$prefix}border-radius-sm),
        3: var(--#{$prefix}border-radius),
        4: var(--#{$prefix}border-radius-lg),
        5: var(--#{$prefix}border-radius-xl),
        6: var(--#{$prefix}border-radius-xxl),
        7: var(--#{$prefix}border-radius-xxxl),
        circle: 50%,
        pill: var(--#{$prefix}border-radius-pill)
      )
    ),

    // Min width
    "min-width": (
      property: min-width,
      class: min-w,
      values: (
        0: 0,
        100: 100
      )
    ),

    // Width
    "width": (
      property: width,
      responsive: true,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        100: 100%,
        auto: auto
      )
    ),

    // Line height
    "line-height": (
      property: line-height,
      class: lh,
      values: (
        0: 0,
        1: 1,
        sm: $line-height-sm,
        base: $line-height-base,
        lg: $line-height-lg,
      )
    ),

    // Text opacity
    "text-opacity": (
      css-var: true,
      class: text-opacity,
      values: (
        25: .25,
        50: .5,
        75: .75,
        80: .8,
        100: 1
      )
    ),

    // Opacity
    "opacity": (
      property: opacity,
      values: (
        0: 0,
        25: .25,
        40: .4,
        50: .5,
        60: .6,
        75: .75,
        100: 1,
      )
    ),
  )
);


// Fix Safari quirk of rounded corners

[class^="rounded"].overflow-hidden,
[class*=" rounded"].overflow-hidden {
  transform: translateZ(0);
}


// Position

.position-lg-absolute {
  @include media-breakpoint-up(lg) {
    position: absolute !important;
  }
}


// Dark mode

@if $enable-dark-mode {
  @include color-mode(dark) {

    // Display utilities
    .d-none-dark {
      display: none !important;
    }
    .d-block-dark {
      display: block !important;
    }
    .d-inline-dark {
      display: inline !important;
    }
    .d-inline-block-dark {
      display: inline-block !important;
    }
    .d-inline-flex-dark {
      display: inline-flex !important;
    }
    .d-flex-dark {
      display: flex !important;
    }

    // Shadows
    .shadow:not([data-bs-theme="light"]) {
      box-shadow: $box-shadow-dark !important;
    }
    .shadow-sm:not([data-bs-theme="light"]) {
      box-shadow: $box-shadow-sm-dark !important;
    }
    .shadow-lg:not([data-bs-theme="light"]) {
      box-shadow: $box-shadow-lg-dark !important;
    }
  }
}
/* stylelint-enable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines */


// Scroll margin-top

.scroll-mt {
  --#{$prefix}scroll-margin-top: #{$scroll-margin-top};
  scroll-margin-top: var(--#{$prefix}scroll-margin-top);

  @include media-breakpoint-down(sm) {
    --#{$prefix}scroll-margin-top: #{$scroll-margin-top * .9};
  }
}
