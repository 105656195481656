// Accordion

.accordion-button {
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-end;
  color: inherit;
  font: {
    size: inherit;
    weight: inherit;
  }

  &::after {
    margin: {
      top: .125em;
      right: $accordion-icon-width * .75;
      left: 0;
    }
  }

  &.collapsed:hover::after {
    transform: rotate(180deg);
  }
}

.accordion-body {
  padding: {
    top: 0;
    left: $accordion-icon-width * 1.75;
  }
}


// Light version

.accordion-light {
  --#{$prefix}accordion-color: #{$accordion-light-button-color};
  --#{$prefix}accordion-active-color: #{$accordion-light-button-active-color};
  --#{$prefix}accordion-btn-icon: #{$accordion-light-button-icon};
  --#{$prefix}accordion-btn-active-icon: #{$accordion-light-button-active-icon};
}
